import React from "react";
import cn from "classnames";
import styles from "./MoreCustomers.module.sass";
import Card from "../../../components/Card";
import Icon from "../../../components/Icon";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const socials = [
  {
    title: "GA Report",
    url: "#",
  },
  {
    title: "Staff Report",
    url: "#",
  },
  {
    title: "Projects Report",
    url: "#",
  },
];

const MoreCustomers = ({ className }) => {
  return (
    <Card
      className={cn(styles.card, className)}
      title="Important Report"
      classTitle="title-blue"
    >
      <div className={styles.comments}>
        <div className={styles.info}>
          There is other important data information not found on this website that you can refer to. Ask Jeff to provide login details to view the following info.
        </div>
        <div className={styles.btns}>
          {socials.map((x, index) => (
            <Link
              className={cn("button-stroke", styles.button)}
              to={x.url}
              target="_blank"
              rel="noopener noreferrer"
              key={index}
            >
              <span>{x.title}</span>
            </Link>
          ))}
        </div>
      </div>
    </Card>
  );
};

export default MoreCustomers;
