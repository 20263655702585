import React, { useState } from "react";
import cn from "classnames";
import styles from "./Notifications.module.sass";
import Item from "../Item";
import Tooltip from "../../../components/Tooltip";
import Switch from "../../../components/Switch";
import TextInput from "../../../components/TextInput";

const settings = [
  {
    id: 0,
    title: "Product updates and community announcements",
    tooltip: "Small description",
  },
  {
    id: 1,
    title: "Market newsletter",
    tooltip: "Small description",
  },
  {
    id: 2,
    title: "Comments",
    tooltip: "Small description",
  },
  {
    id: 3,
    title: "Purchases",
    tooltip: "Small description",
  },
];

const OtherInfo = ({ className, values, setValues }) => {
  const radioButtonChange = (e) => {
    const name = e.target.name;
    const checked = e.target.checked;
    setValues({ ...values, [name]: checked });
  };

  return (
    <Item
      className={cn(styles.card, className)}
      title="Other Info"
      classTitle="title-red"
    >
      <div className={styles.list}>
        <div className={styles.line}>
          <div className={styles.title}>Full-time</div>
          <Switch
            className={styles.switch}
            value={values?.is_full_time}
            onChange={radioButtonChange}
            name="is_full_time"
          />
        </div>
        <div className={styles.line}>
          <div className={styles.title}>Office Staff</div>
          <Switch
            className={styles.switch}
            value={values?.is_office_staff}
            onChange={radioButtonChange}
            name="is_office_staff"
          />
        </div>
        <div className="mb-20">
        <TextInput
          className={styles.field}
          label="Salary Amount"
          name="salary_amount"
          required
          values={values}
          setValues={setValues}
          type="number"
        />
        </div>

      </div>
    </Item>
  );
};

export default OtherInfo;
