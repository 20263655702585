import React, { useEffect, useState } from "react";
import styles from "./ProductActivity.module.sass";
import cn from "classnames";
import Card from "../../../components/Card/index.js";
import { serverColumns } from "./data.js";
import {
  dateFormatter,
  dateFormatter2,
  expiryDateCalculator,
  getMonthFromDate,
} from "../../../utils.js";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/Loader/index.js";
import { projectListGenereate } from "../../HostingServerPage/ServerTableCard/index.js";
import {
  fetchAllReportHistory,
  reportSelector,
} from "../../../redux/reportSlice.js";
import Pill from "../../../MyComponents/Pill/Pill.jsx";
import DetailReport from "../DetailReport/DetailReport.jsx";

const Table = () => {
  const { isLoading, data } = useSelector(reportSelector);
  const [detail, setDetail] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAllReportHistory());
  }, []);

  const viewDetailClickHandler = (data) => {
    setDetail(data);
  };

  const closer = () => {
    setDetail(null);
  };



  return (
    <>
      {detail && <DetailReport data={detail} closer={closer} />}
      {isLoading && <Loader className="page_loader" />}
      <Card
        className={styles.card}
        title="Cost Report History"
        classTitle="title-green"
      >
        <div className={styles.table}>
          <div className={styles.row}>
            {serverColumns?.map((x) => (
              <div className={styles.col}>{x}</div>
            ))}
          </div>
          {data?.map((x, index) => (
            <div className={styles.row} key={x.id}>
              <div className={styles.col}>
                <div className={styles.label}>{serverColumns[0]}</div>
                <div>
                  <div>{getMonthFromDate(x.report_from)}</div>
                </div>
              </div>
              <div className={styles.col}>
                <div className={styles.label}>{serverColumns[1]}</div>
                <Pill isGreenDark> ${x.total_amount}</Pill>
              </div>
              <div className={styles.col}>
                <div className={styles.label}>{serverColumns[2]}</div>
                <button
                  onClick={() => viewDetailClickHandler(x)}
                  className="button"
                >
                  View Detail Report
                </button>
              </div>
            </div>
          ))}
        </div>
      </Card>
    </>
  );
};

export default Table;
