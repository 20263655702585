export const requiredProjectFields = [
    {
        name: "title",
        label: "Project Title",
        required: true
    },
    {
        name: "code",
        label: "Project Code",
        required: true
    },
    {
        name: "category",
        label: "Category",
        required: true
    },
    {
        name: "description",
        label: "Description",
        required: true
    },
]

export const ProjectCategory = {
  Listing : "Listing",
  Forum : "Forum",
  News : "News",
  Live_score : "Live Score",
  Landing_page : "Landing Page",
  Video : "Video",
  Other : "Other"
}


export const projectCategoryOptions = [
    {
      label: ProjectCategory.Listing,
      value:  ProjectCategory.Listing,
    },
    {
      label:  ProjectCategory.Forum,
      value: ProjectCategory.Forum,
    },
    {
      label:ProjectCategory.News,
      value: ProjectCategory.News,
    },
    {
      label: ProjectCategory.Live_score,
      value:  ProjectCategory.Live_score,
    },
    {
      label:  ProjectCategory.Landing_page,
      value: ProjectCategory.Landing_page,
    },
    {
      label: ProjectCategory.Video,
      value: ProjectCategory.Video,
    },
    {
      label: ProjectCategory.Other,
      value: ProjectCategory.Other,
    },
]

export const INDIA = "India";
export const PAKISTAN = "Pakistan";
export const BANGLADESH = "Bangladesh";
export const VIETNAM = "Vietnam";
export const COMBODIA = "Cambodia";
  
export const countriesOptions = [
    { label: INDIA, value: INDIA },
    { label: PAKISTAN, value: PAKISTAN},
    { label: BANGLADESH, value: BANGLADESH },
    { label: VIETNAM, value: VIETNAM },
    { label: COMBODIA, value: COMBODIA }
];

