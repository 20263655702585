export const serverColumns = [
 'Date Purchase',
 "Platform",
 "Plugin Name",
 "Price",
 "No. of Web",
 "Monthly/Web",
 "Actions"
]


export const reportData = [
    {
      title: "1 Oct - 15 Oct",
      project: {
        counter: "Player Vip",
        color: "#B5E4CA",
      },
      registrations: {
        counter: "24k",
        color: "#CABDFF",
        value: 37.8,
      },
      session: {
        counter: 48,
        color: "#B1E5FC",
        value: -37.8,
      },
      staff: {
        counter: 16,
        color: "#CABDFF",
        value: -56,
      },
      spr: {
        counter: 16,
        color: "#B5E4CA",
        value: -56,
      },
      cps: {
        counter: 16,
        color: "#FFD88D",
        value: -56,
      },
      cpr: {
        counter: 16,
        color: "#FFD88D",
        value: -56,
      },
    },
    {
      title: "1 Oct - 15 Oct",
      project: {
        counter: "Bj baji Landing",
        color: "#B5E4CA",
      },
      registrations: {
        counter: "24k",
        color: "#CABDFF",
        value: 37.8,
      },
      session: {
        counter: 48,
        color: "#B1E5FC",
        value: -37.8,
      },
      staff: {
        counter: 16,
        color: "#CABDFF",
        value: -56,
      },
      spr: {
        counter: 16,
        color: "#B5E4CA",
        value: -56,
      },
      cps: {
        counter: 16,
        color: "#FFD88D",
        value: -56,
      },
      cpr: {
        counter: 16,
        color: "#FFD88D",
        value: -56,
      },
    },

  ];