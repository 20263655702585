import React, { useEffect, useMemo } from "react";
import cn from "classnames";
import styles from "./Overview.module.sass";
import TooltipGlodal from "../../../components/TooltipGlodal";
import Card from "../../../components/Card";
import Icon from "../../../components/Icon";
import Tooltip from "../../../components/Tooltip";
import Balance from "../../../components/Balance";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchExpenseOverview,
  selectExpensesOverview,
} from "../../../redux/expensesSlice";
import { getRecentMonthOptions } from "../../../utils";

const Overview = ({ cardsData, className, hideIncrement }) => {

  const overviewData = useSelector(selectExpensesOverview);


  const data = {
    office: {
      value: 0,
      counter: "$0",
    },
    remote: {
      value: 0,
      counter: `$${overviewData?.selectedMonthExpense?.labour_spending || 0}`,
    },
    utility: {
      value: 0,
      counter: `$${overviewData?.selectedMonthExpense?.expense_spending || 0}`,
    },
  };
  return (
    <>
      <Card className={cn(styles.card, className)}>
        <div className={styles.overview}>
          <div className={styles.list}>
            {cardsData.map((x, index) => (
              <div className={styles.item} key={index}>
                <div
                  className={styles.icon}
                  style={{ backgroundColor: x.color }}
                >
                  <Icon name={x.icon} size="24" />
                </div>
                <div className={styles.details}>
                  <div className={styles.label}>
                    {x.title}
                    <Tooltip
                      className={styles.tooltip}
                      title={x.tooltip}
                      icon="info"
                      place="top"
                    />
                  </div>
                  <div className={styles.counter}>{data[x.type]?.counter}</div>
                  {/* {!hideIncrement && (
                    <div className={styles.indicator}>
                      <Balance
                        className={styles.balance}
                        value={data[x.type]?.value}
                      />
                      <span>this week</span>
                    </div>
                  )} */}
                </div>
              </div>
            ))}
          </div>
        </div>
      </Card>
      <TooltipGlodal />
    </>
  );
};

export default Overview;
