import React, { useMemo } from "react";
import UserProjects from "./UserProjects";

const TeamsSection = ({ teams, projectInfo, showDetail, month }) => {
  const teamsRemoveDuplicateUser = useMemo(() => {
    let teamUserIds = [];
    let filterArray = [];
    teams?.forEach((each) => {
      if (teamUserIds?.includes(each.user_id)) {
        return null;
      }
      teamUserIds.push(each.user_id);
      filterArray.push(each);
    });
    return filterArray;
  }, [teams]);

  return (
    <div className={`teams_cont ${!showDetail && " hidden_card"}`}>
      <h3 className="section_heading">Teams List</h3>
      <div className="team_grid">
        {teamsRemoveDuplicateUser?.map((team, i) => (
          <div className="team_card">
            <h4>{`${i + 1} . ${team.user?.name} `}</h4>
            <div className="pl-20">
              <UserProjects
                month={month}
                latest_salary_submission={team?.user?.latest_salary_submission}
                salary_amount={team.user?.salary_amount || 0}
                project_teams={team?.user?.project_teams}
                project_id={projectInfo?.id}
                showDetail={showDetail}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TeamsSection;
