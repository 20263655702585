import React from "react";
import cn from "classnames";
import styles from "./TextInput.module.sass";
import Icon from "../Icon";
import Tooltip from "../Tooltip";
import './TextInput.scss'

const TextArea = ({
  className,
  classLabel,
  classInput,
  label,
  icon,
  copy,
  currency,
  tooltip,
  place,
  values,
  setValues,
  name,
  errors,
  setErrors,
  ...props
}) => {

  const inputChangeHandler = (e) => {
    if (setValues)
      setValues({ ...values, [name]: e?.target?.value || "" })
    if (errors && errors?.[name])
      setErrors({ ...errors, [name]: null })

  }
  return (
    <div
      className={cn(
        styles.field,
        { [styles.fieldIcon]: icon },
        { [styles.fieldCopy]: copy },
        { [styles.fieldCurrency]: currency },
        "textarea",
        className
      )}
    >
      {label && (
        <div className={cn(classLabel, styles.label)}>
          {label}{" "}
          {tooltip && (
            <Tooltip
              className={styles.tooltip}
              title={tooltip}
              icon="info"
              place={place ? place : "right"}
            />
          )}
        </div>
      )}
      <div className={styles.wrap}>
        <textarea
          onChange={inputChangeHandler}
          className={cn(classInput, styles.input, errors?.[name]?.length && "error_input")}
          {...props}
          value ={values?.[name]||""}
        />
        {icon && (
          <div className={styles.icon}>
            <Icon name={icon} size="24" />{" "}
          </div>
        )}
        {copy && (
          <button className={styles.copy}>
            <Icon name="copy" size="24" />{" "}
          </button>
        )}
        {currency && <div className={styles.currency}>{currency}</div>}
      </div>
      {
        errors && errors?.[name]?.length && <div className="input_errors_cont">
          {
            errors?.[name]?.map(error => <p className="error_msg">{error}</p>)
          }
        </div>
      }
    </div>
  );
};

export default TextArea;
