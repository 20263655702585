import React, { useMemo, useState } from "react";
import cn from "classnames";
import styles from "./ProfileInformation.module.sass";
import Item from "../Item";
import Icon from "../../../components/Icon";
import TextInput from "../../../components/TextInput";
import UploadFileButton from "../../../MyComponents/UploadFileButton/UploadFileButton";
import CustomDropdown from "../../../components/Dropdown/CustomDropdown";
import { countryOptions, postionOptions } from "./data";
import CustomImage from "../../../components/CustomImage/CustomImage";

const ProfileInformation = ({ className, values, setValues }) => {

  const imageGenerate = useMemo(() => {
    if (values?.profile_image)
      return URL.createObjectURL(values?.profile_image);
    return null;
  }, [values?.profile_image]);


  
  const removeProfileImageHandler = () => {
    setValues({ ...values, profile_image: null });
  };

  return (
    <Item
      className={cn(styles.card, className)}
      title="Profile information"
      classTitle="title-green"
    >
      <div className={styles.profile}>
        <div className={styles.avatar}>
          {imageGenerate ? (
            <img src={imageGenerate} alt="Avatar" />
          ) : (
            <CustomImage
              src={values?.prefil_profile_image }
              alt="Avatar"
            />
          )}

          {imageGenerate && (
            <button
              type="button"
              onClick={removeProfileImageHandler}
              className={styles.remove}
            >
              <Icon name="close" />
            </button>
          )}
        </div>
        <UploadFileButton
          name="profile_image"
          accept="image/*"
          values={values}
          setValues={setValues}
        />
      </div>
      <div className={styles.fieldset}>
        <TextInput
          className={styles.field}
          label="Display name"
          name="name"
          required
          values={values}
          setValues={setValues}
        />
        <div className="mb-20">
          <CustomDropdown
            label="Country"
            name="country"
            options={countryOptions}
            values={values}
            setValues={setValues}
          />
        </div>
        <div className="mb-20">
          <CustomDropdown
            label="Position"
            name="position"
            options={postionOptions}
            values={values}
            setValues={setValues}
          />
        </div>

        <TextInput
          className={styles.field}
          label="Email"
          name="email"
          type="email"
          disabled
          values={values}
          setValues={setValues}
        />
        <div className="mb-20">
          <TextInput
            className={styles.field}
            label="BJT profile Link"
            name="bjt_profile_link"
            required
            values={values}
            setValues={setValues}
          />
        </div>
      </div>
    </Item>
  );
};

export default ProfileInformation;
