import React, { useState } from "react";
import styles from "./Control.module.sass";
import cn from "classnames";
import Icon from "../../../../components/Icon";
import Actions from "../../../../components/Actions";
import ModalAffliateSubmission from "../../../../MyComponents/ModalAffliateSubmission/ModalAffliateSubmission";

const Control = ({
  className,
  visibleActions,
  setVisibleActions,
  up,
  actionClickHandler,
  project
}) => {
  const [showAffliate, setshowAffliate] = useState(false);

  const actions = [
    {
      title: "Edit product",
      icon: "edit",
      action: () => actionClickHandler("edit"),
    },
    {
      title: "Delete forever",
      icon: "trash",
      action: () => actionClickHandler("delete"),
    },
    {
      title: "Affliate Report",
      icon: "message",
      action: () => setshowAffliate(true),
    },
  ];
  return (
    <>
      {showAffliate && (
        <ModalAffliateSubmission
          visible={showAffliate}
          setIsVisible={setshowAffliate}
          project={project}
        />
      )}
      <div className={cn(styles.control, className)}>
        <button
          onClick={() => actionClickHandler("edit")}
          className={styles.button}
        >
          <Icon name="edit" size="20" />
        </button>
        <button
          onClick={() => actionClickHandler("delete")}
          className={styles.button}
        >
          <Icon name="trash" size="20" />
        </button>
        <Actions
          className={styles.actions}
          classActionsHead={styles.actionsHead}
          classActionsBody={styles.actionsBody}
          classActionsOption={styles.actionsOption}
          items={actions}
          visible={visibleActions}
          setVisible={setVisibleActions}
          up={up}
        />
      </div>
    </>
  );
};

export default Control;
