import React, { useMemo } from "react";
import { numberWithCommas } from "../../../../utils";
import Pill from "../../../../MyComponents/Pill/Pill";

const ExpenseCard = ({ expense, i, project_id, showDetail }) => {
  const monthlyConst = useMemo(() => {
    let project_count = expense?.projects?.length;
    let monthly_cost_per_web =
      expense.amount / (expense.months * (project_count || 1));
    monthly_cost_per_web = numberWithCommas(monthly_cost_per_web?.toFixed(2));
    return monthly_cost_per_web;
  }, [expense]);
  return (
    <div className={`expense_card `}>
      <div className="flex align-center gap-20">
        <h4>
          {i + 1} . {expense?.title || expense?.company}
        </h4>
        <Pill isGreenDark>{expense?.type || ""}</Pill>
      </div>

      <ol>
        {showDetail && (
          <>
            <li className="row_data">
              Total Cost : <b>{expense?.amount}</b>
            </li>
            <li className="row_data">
              Renewal Amount : <b>{expense?.renewal_amount}</b>
            </li>
            <li className="row_data">
              Purchase Date : <b>{expense?.purchase_date}</b>
            </li>
            <li className="row_data">
              Months Valid : <b>{expense?.months}</b>
            </li>
            <li className="row_data">
              Total Projects Using ({expense?.projects?.length}) : <br />
              <ol className="pl-20">
                {expense.projects?.map((each, i) => (
                  <li>
                    {i + 1}. {each.title}
                  </li>
                ))}
              </ol>
            </li>
          </>
        )}

        <h4 className="billed row_data">
          Billed Cost : $
          <span className={`expense-${project_id} cost-${project_id}`}>{monthlyConst}</span>
        </h4>
      </ol>
    </div>
  );
};

export default ExpenseCard;
