import React from "react";
import cn from "classnames";
import styles from "./CategoryAndAttibutes.module.sass";
import Card from "../../../components/Card";
import Tooltip from "../../../components/Tooltip";
import Checkbox from "../../../components/Checkbox";
import CustomDropdown from "../../../components/Dropdown/CustomDropdown";
import { useSelector } from "react-redux";
import { selectExpenseApiOptions, selectExpensePluginOptions, selectExpenseThemeOptions } from "../../../redux/expensesSlice";


const CategoryAndAttibutes = ({ className, values, setValues }) => {

  const handleChange = (id, name) => {
    if (values?.[name]?.includes(id)) {
      let filter_data = values?.[name]?.filter((x) => x !== id);
      setValues({ ...values, [name]: filter_data })

    } else {
      let filter_data = [...(values?.[name] || []), id]
      setValues({ ...values, [name]: filter_data })
    }
  };


  const themeOptions = useSelector(selectExpenseThemeOptions)
  const pluginOptions = useSelector(selectExpensePluginOptions)
  const apiProviderOptions = useSelector(selectExpenseApiOptions)


  return (
    <Card
      className={cn(styles.card, className)}
      title="Theme & Plugin"
      classTitle="title-purple"
    >
      <div className={styles.images}>
        <div className={styles.field}>
          <CustomDropdown
            label="Theme Usage"
            placeholder="Select Theme"
            tooltip="Select One of the Theme"
            values={values}
            setValues={setValues}
            options={themeOptions}
            isClearable
            name="theme_name"
          />
        </div>
 

        <div className={styles.label}>
          Paid Plugin{" "}
          <Tooltip
            className={styles.tooltip}
            title="Maximum 100 characters. No HTML or emoji allowed"
            icon="info"
            place="right"
          />
        </div>
        <div className={styles.list}>
          {pluginOptions?.map((x, index) => (
            <Checkbox
              className={styles.checkbox}
              content={x.label}
              value={values?.paid_plugin?.includes(x.value)}
              onChange={() => handleChange(x.value, 'paid_plugin')}
              key={index}
            />
          ))}
        </div>

        <div className={styles.field}>
          <CustomDropdown
            label="Api Providers"
            placeholder="Select Api Providers"
            // tooltip="Select One of the Theme"
            values={values}
            setValues={setValues}
            options={apiProviderOptions}
            isClearable
            name="api_provider"
            required={false}
          />
        </div>

      </div>
    </Card>
  );
};

export default CategoryAndAttibutes;
