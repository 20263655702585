import React, { useState } from "react";
import styles from "./Market.module.sass";
import Checkbox from "../../../../components/Checkbox";
import Row from "./Row";

const Table = ({ items ,deleteProjectHandler }) => {
  const [chooseAll, setСhooseAll] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);

  const handleChange = (id) => {
    if (selectedFilters.includes(id)) {
      setSelectedFilters(selectedFilters.filter((x) => x !== id));
    } else {
      setSelectedFilters((selectedFilters) => [...selectedFilters, id]);
    }
  };


  return (
    <div className={styles.market}>
      <div className={styles.table}>
        <div className={styles.row}>
          {/* <div className={styles.col}>
            <Checkbox
              className={styles.checkbox}
              value={chooseAll}
              onChange={() => setСhooseAll(!chooseAll)}
            />
          </div> */}
          <div className={styles.col}>Project</div>
          <div className={styles.col}>Status</div>
          <div className={styles.col}>Monthly Cost</div>
          <div className={styles.col}>Reg.</div>
          <div className={styles.col}>Total Cost</div>
          <div className={styles.col}>Total CPR</div>
        </div>
        {items?.map((x, index) => (
          <Row
            item={x}
            key={index}
            up={items.length - index <= 2}
            value={selectedFilters.includes(x.id)}
            onChange={() => handleChange(x.id)} 
            deleteProjectHandler ={deleteProjectHandler}
          />
        ))}
      </div>
    </div>
  );
};

export default Table;
