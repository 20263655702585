import React, { useState } from "react";
import cn from "classnames";
import styles from "./ProductFiles.module.sass";
import Card from "../../../components/Card";
import CustomDropdown from "../../../components/Dropdown/CustomDropdown";
import { AllTeams, currentTeamInputs, teamsInputs } from "./data";
import { useSelector } from "react-redux";
import { staffOptionsSelector } from "../../../redux/staffSlice";


const ProductFiles = ({ className, values, setValues, errors, setErrors }) => {


  const staffOptions = useSelector(staffOptionsSelector);

  return (
    <Card
      className={cn(styles.card, className)}
      title="Teams"
      classTitle="title-blue"
    >
      <div className="two_input_columns_grid">
        {
          AllTeams?.map(each => <div >
            <CustomDropdown
              isMulti
              tooltip={each?.tooltip}
              name={each?.id}
              options={staffOptions}
              label={each?.name}
              placeholder={`Select ${each?.name}`}
              values={values}
              setValues={setValues}
              isClearable
              errors={errors}
              setErrors={setErrors}
            />
          </div>)
        }

      </div>

    </Card>
  );
};

export default ProductFiles;
