import React, { useEffect, useMemo, useState } from "react";
import cn from "classnames";
import styles from "./Overview.module.sass";
import TooltipGlodal from "../../../components/TooltipGlodal";
import Card from "../../../components/Card";
import Dropdown from "../../../components/Dropdown";
import Icon from "../../../components/Icon";
import Tooltip from "../../../components/Tooltip";
import Balance from "../../../components/Balance";
import Chart from "./Chart";
import { useDispatch, useSelector } from "react-redux";
import {
  ProjectSlectorWithAflliateAndGaGroup,
  projectSelector,
} from "../../../redux/projectSlice";
import { fetchTrafficOverview, selectTrafficOverview } from "../../../redux/gaSubmissionSlice";

const intervals = ["This week", "This month", "This year"];



const Overview = ({ className }) => {
  const [sorting, setSorting] = useState(intervals[0]);
  const groupedProjects = useSelector(ProjectSlectorWithAflliateAndGaGroup);
  // console.log(groupedProjects, "overview grouped projects");




  // const overViewData = useMemo(() => {
  //   if (!groupedProjects) return null;
  //   let overview_data = {
  //     registration: {
  //       counter: 0,
  //       value: 0,
  //       data: [],
  //     },
  //     session: {
  //       counter: 0,
  //       value: 0,
  //       data: [],
  //     },
  //     single_staff_traffic: {
  //       counter: 0,
  //       value: 0,
  //       data: [],
  //     },
  //     session_for_one_reg: {
  //       counter: 0,
  //       value: 0,
  //       data: [],
  //     },
  //     cost_of_one_session: {
  //       counter: 0,
  //       value: 0,
  //       data: [],
  //     },
  //     cost_of_registration: {
  //       counter: 0,
  //       value: 0,
  //       data: [],
  //     },
  //   };
  //   // groupedProjects?.forEach((project) => {
  //   //   project?.group_report_month?.forEach((data, i) => {
  //   //     let ga = data.ga_submission;
  //   //     let affiliate = data?.affiliate;

  //   //     overview_data.session.counter += ga?.session || 0;
  //   //     overview_data.session.data.push({
  //   //       name: i,
  //   //       earning: ga?.session || 0,
  //   //     });
  //   //     overview_data.registration.counter +=
  //   //       (affiliate?.registrations || 0);
  //   //       overview_data.registration.data.push({
  //   //         name: i,
  //   //         earning: affiliate?.registrations || 0,
  //   //       })
  //   //   });
  //   // });
  //   return overview_data;
  // }, []);


  const overviewData = useSelector(selectTrafficOverview);


  const items = [
    {
      tooltip: "Total registrations in ***",
      title: "Registration",
      counter: overviewData?.total_registrations||0,
      icon: "activity",
      value: 0,
      background: "#ecf9fe",
      chartColor: "#83BF6E",
      data: [],
    },
    {
      tooltip: "Total engaged sessions of all projects",
      title: "Session",
      counter: overviewData?.total_sessions  || 0,
      icon: "activity",
      value: 0,
      background: "#ecf9fe",
      chartColor: "#2A85FF",
      data: [],
    },
    {
      title: "Single staff traffic",
      counter: overviewData?.single_staff_traffic || 0,
      icon: "activity",
      value: 0,
      background: "#ecf9fe",
      chartColor: "#8E59FF",
      data: [],
    },
  ];

  const items2 = [
    {
      tooltip: "E session per registration , How many visit can get 1 register",
      title: "Session for 1 Reg.",
      counter: overviewData?.session_per_registration||0,
      icon: "activity",
      value: 0,
      background: "#f8eded",
      chartColor: "#83BF6E",
      data: [],
    },
    {
      title: "Cost of 1 Session",
      counter: `$ ${overviewData?.cost_per_session||0}`,
      icon: "activity",
      value: 0,
      background: "#f8eded",
      chartColor: "#2A85FF",
      data: [],
    },
    {
      title: "Cost of Registration",
      counter: `$ ${overviewData?.cost_per_registraion||0}`,
      icon: "activity",
      value: 0,
      background: "#f8eded",
      chartColor: "#8E59FF",
      data: [],
    },
  ];


  const dispatch = useDispatch()
  useEffect(()=>{
    dispatch(fetchTrafficOverview())
  },[])


  return (


    <>
      <Card
        className={cn(styles.card, className)}
        title="Overview"
        classTitle="title-purple"
        // head={
        //   <Dropdown
        //     className={styles.dropdown}
        //     classDropdownHead={styles.dropdownHead}
        //     value={sorting}
        //     setValue={setSorting}
        //     options={intervals}
        //     small
        //   />
        // }
      >
        <div className={styles.overview}>
          <div className={styles.list}>
            {items.map((x, index) => (
              <div
                className={styles.item}
                key={index}
                style={{ backgroundColor: x.background }}
              >
                <div className={styles.icon}>
                  <Icon name={x.icon} size="24" />{" "}
                </div>
                <div className={styles.line}>
                  <div className={styles.details}>
                    <div className={styles.category}>
                      {x.title}
                      <Tooltip
                        className={styles.tooltip}
                        title={x.tooltip || ""}
                        icon="info"
                        place="right"
                      />
                    </div>
                    <div className={styles.counter}>{x.counter}</div>
                    {/* <div className={styles.indicator}>
                      <Balance className={styles.balance} value={x.value} />
                      <span>previous month</span>
                    </div> */}
                    <div
                      style={{ marginTop: "5px" }}
                      className={styles.category}
                    >
                      Prefer Higher
                    </div>
                  </div>
                  <Chart className={styles.chart} item={x} />
                </div>
              </div>
            ))}
          </div>
          <div style={{ marginTop: "15px" }} className={styles.list}>
            {items2.map((x, index) => (
              <div
                className={styles.item}
                key={index}
                style={{ backgroundColor: x.background }}
              >
                <div className={styles.icon}>
                  <Icon name={x.icon} size="24" />{" "}
                </div>
                <div className={styles.line}>
                  <div className={styles.details}>
                    <div className={styles.category}>
                      {x.title}
                      <Tooltip
                        className={styles.tooltip}
                        title="Small description"
                        icon="info"
                        place="right"
                      />
                    </div>
                    <div className={styles.counter}>{x.counter}</div>
                    {/* <div className={styles.indicator}>
                      <Balance className={styles.balance} value={x.value} />
                      <span>previous month</span>
                    </div> */}
                    <div
                      style={{ marginTop: "5px" }}
                      className={styles.category}
                    >
                      Prefer Lower
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Card>
      <TooltipGlodal />
    </>
  );
};

export default Overview;
