import React from "react";
import styles from "./Item.module.sass";
import cn from "classnames";
import Balance from "../../../../components/Balance";

const Item = ({ className, value, percentage, color }) => {
  return (
    <div className={cn(styles.item, className)}>
      <div className={styles.counter} style={{ backgroundColor: color }}>
        {value}
      </div>
      {/* {item.percentate && <Balance className={styles.balance} value={item.percentate} />} */}
    </div>
  );
};

export default Item;
