import React from "react";
import styles from "./TopCountries.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import { useSelector } from "react-redux";
import { selectExpensesOverview } from "../../../redux/expensesSlice";
import { getAvatar } from "../../../utils";


const TopCountries = ({ className }) => {
  const overviewData = useSelector(selectExpensesOverview);

  return (
    <Card
      className={cn(styles.card, className)}
      title="Utilties"
      classTitle="title-blue"
    >
      <div className={styles.countries}>
        {overviewData?.utilities?.map((x, index) => (
          <div className={styles.item} key={index}>
            <div className={styles.flag}>
              <span role="img" aria-label={x.title}>
                {getAvatar(x.title || x.company)}
              </span>
            </div>
            <div className={styles.title}>{x.title || x.company}</div>
            <div className={styles.price}>${x.amount.toFixed(2)}</div>
          </div>
        ))}
      </div>
    </Card>
  );
};

export default TopCountries;
