import React from "react";
import cn from "classnames";
import styles from "./Payment.module.sass";
import Item from "../Item";
import Tooltip from "../../../components/Tooltip";
import CustomDropdown from "../../../components/Dropdown/CustomDropdown";
import { paymentOptionTypes, paymentTypeOptions } from "./data";
import TextInput from "../../../components/TextInput";
import TextArea from "../../../components/TextInput/TextArea";

const Payment = ({ className, values, setValues }) => {
  return (
    <Item
      className={cn(styles.card, className)}
      title="Payment"
      classTitle="title-green"
    >
      <div className="mb-20">
        <CustomDropdown
          label="Payment Type"
          name="payment"
          options={paymentTypeOptions}
          values={values}
          setValues={setValues}
        />
      </div>
      {values?.payment?.value === paymentOptionTypes.upwork && (
        <TextInput
          className="mb-20"
          label="Upwork Profile Link"
          name="upwork_id"
          required
          values={values}
          setValues={setValues}
          type="text"
        />
      )}
      {values?.payment?.value === paymentOptionTypes.fiver && (
        <TextInput
          className="mb-20"
          label="Fiver Profile Link"
          name="fiver_id"
          required
          values={values}
          setValues={setValues}
          type="text"
        />
      )}
      {values?.payment?.value === paymentOptionTypes.freelance && (
        <TextInput
          className="mb-20"
          label="Freelancer Profile Link"
          name="freelancer_id"
          required
          values={values}
          setValues={setValues}
          type="text"
        />
      )}
      {values?.payment?.value === paymentOptionTypes.paypal && (
        <TextInput
          className="mb-20"
          label="Paypal Email"
          name="paypal_email"
          required
          values={values}
          setValues={setValues}
          type="email"
        />
      )}
      {values?.payment?.value === paymentOptionTypes.localBank && (
        <TextArea
          className="mb-20"
          label="Local Bank Details"
          name="local_bank_details"
          required
          values={values}
          setValues={setValues}
          type="email"
          rows="8"
          tooltip="Local bank details should contain the Candidate Name , Bank name , country , Bank Branch name  and IFSC code"
        />
      )}
      {values?.payment?.value === paymentOptionTypes.Binance && (
        <>
          <TextInput
            className="mb-20"
            label="Binance Email"
            name="binance_email"
            required
            values={values}
            setValues={setValues}
            type="email"
          />
          <TextInput
            className="mb-20"
            label="Usdt Address"
            name="usdt_address"
            required
            values={values}
            setValues={setValues}
            type="text"
          />
        </>
      )}
    </Item>
  );
};

export default Payment;
