import React, { useEffect, useState } from "react";
import HistoryItem from "./HistoryItem/HistoryItem";

const ProjectCost = ({ project, month }) => {
  const [totalCost, setTotalCost] = useState(0);
  const [expenseCost, setExpensesCost] = useState(0);

  useEffect(() => {
    if (project) {
      setTimeout(() => {
        let sum = 0;
        const costs = document.querySelectorAll(`.cost-${project.id}`);
        for (let i = 0; i < costs.length; i++) {
          let cost = +costs[i]?.innerHTML;
          sum += cost;
        }
        setTotalCost(sum?.toFixed(2));

        let expenses_sum = 0;
        const expenses = document.querySelectorAll(`.expense-${project.id}`);
        for (let i = 0; i < expenses.length; i++) {
          let expense = +expenses[i]?.innerHTML;
          expenses_sum += expense;
        }

        console.log(sum, "Total Cost");
        console.log(expenses_sum, "Expense Cost");
        setExpensesCost(expenses_sum?.toFixed(2));
      }, 1000);
    }
  }, [project, month]);

  return (
    <div className="project_root">
      <HistoryItem month={month} projectInfo={project} />
      <div className="flex flex-wrap gap-20 aligin-center justify-between mt-20">
        <div className="flex align-center gap-20">
          <h3>
            labour Cost : $
            <span project_id={project?.id} className="labour_cost">
              {totalCost - expenseCost}
            </span>
          </h3>
          <h3>
            Expense Cost : $
            <span project_id={project?.id} className="expense_cost">
              {expenseCost}
            </span>
          </h3>
        </div>

        <h3 className="">
          Total Cost of Project : $
          <span project_id={project?.id} className="project_cost">
            {totalCost}
          </span>
        </h3>
      </div>
    </div>
  );
};

export default ProjectCost;
