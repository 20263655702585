import React, { useState, useRef, useEffect } from "react";
import cn from "classnames";
import styles from "./Settings.module.sass";
import TooltipGlodal from "../../components/TooltipGlodal";
import Dropdown from "../../components/Dropdown";
import ProfileInformation from "./ProfileInformation";
import Login from "./Login";
import OtherInfo from "./OtherInfo";
import Payment from "./Payment";
import UseApiCalling from "../../hooks/UseApiCalling";
import Loader from "../../components/Loader";
import { useDispatch, useSelector } from "react-redux";
import { selectUserInfo, setUserInfo } from "../../redux/userSlice";
import { countryOptions, postionOptions } from "./ProfileInformation/data";
import { paymentTypeOptions } from "./Payment/data";

const Settings = () => {
  const navigation = [
    {
      title: "Basics",
      action: () =>
        scrollToProfile.current.scrollIntoView({ behavior: "smooth" }),
    },
    {
      title: "Other Info",
      action: () =>
        scrollToNotifications.current.scrollIntoView({ behavior: "smooth" }),
    },
    {
      title: "Payment",
      action: () =>
        scrollToPayment.current.scrollIntoView({ behavior: "smooth" }),
    },
    {
      title: "Account",
      action: () =>
        scrollToLogin.current.scrollIntoView({ behavior: "smooth" }),
    },
  ];
  const options = [];
  navigation.map((x) => options.push(x.title));
  const [activeTab, setActiveTab] = useState(options[0]);
  const [activeIndex, setActiveIndex] = useState(0);
  const scrollToProfile = useRef(null);
  const scrollToLogin = useRef(null);
  const scrollToNotifications = useRef(null);
  const scrollToPayment = useRef(null);
  const { isLoading, callApi } = UseApiCalling();

  const [values, setValues] = useState({});

  const handleClick = (x, index) => {
    setActiveIndex(index);
    x.action();
  };

  const user = useSelector(selectUserInfo);
  const dispatch = useDispatch();

  const cb = (res, isSuccess) => {
    if (isSuccess) {
      let data = res.data;
      data.token = user.token;
      dispatch(setUserInfo(data));
    }
  };

  const formSubmitHandler = (e) => {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append("name", values?.name);
    formdata.append("country", values?.country.value);
    formdata.append("bjt_profile_link", values?.bjt_profile_link);
    formdata.append("is_full_time", values?.is_full_time ? 1 : 0);
    formdata.append("is_office_staff", values?.is_office_staff ? 1 : 0);
    formdata.append("salary_amount", values?.salary_amount);
    formdata.append("payment", values?.payment?.value);
    formdata.append("fiver_id", values?.fiver_id || "");
    formdata.append("upwork_id", values?.upwork_id || "");
    formdata.append("freelancer_id", values?.freelancer_id || "");
    formdata.append("paypal_email", values?.paypal_email || "");
    formdata.append("local_bank_details", values?.local_bank_details || "");
    formdata.append("binance_email", values?.binance_email || "");
    formdata.append("usdt_address", values?.usdt_address || "");
    formdata.append("position", values?.position?.value || "");
    formdata.append("_method", "put");
    if (values?.profile_image) {
      formdata.append("profile_image", values?.profile_image);
    }
    callApi("/update-profile", formdata, "post", cb);
  };

  console.log(values, "some values");
  useEffect(() => {
    const {
      name,
      email,
      country,
      bjt_profile_link,
      is_full_time,
      is_office_staff,
      salary_amount,
      payment,
      fiver_id,
      freelancer_id,
      paypal_email,
      local_bank_details,
      binance_email,
      usdt_address,
      salary_claim_count,
      contribution_points,
      profile_image,
      position,
    } = user;

    const update = {
      name,
      email,
      country: countryOptions?.find((each) => each.value === country),
      bjt_profile_link,
      is_full_time,
      is_office_staff,
      salary_amount,
      payment: paymentTypeOptions?.find((each) => each.value === payment),
      position: postionOptions?.find((each) => each.value === position),
      fiver_id,
      freelancer_id,
      paypal_email,
      local_bank_details,
      binance_email,
      usdt_address,
      salary_claim_count,
      contribution_points,
      prefil_profile_image: profile_image,
    };
    setValues(update);
  }, []);
  // prefil_profile_image
  console.log(user, "some user");
  return (
    <>
      {isLoading && <Loader className="page_loader" />}
      <div className={styles.settings}>
        <div className={styles.menu}>
          {navigation.map((x, index) => (
            <button
              className={cn(styles.button, {
                [styles.active]: activeIndex === index,
              })}
              key={index}
              onClick={() => handleClick(x, index)}
            >
              {x.title}
            </button>
          ))}
        </div>
        <div className={styles.wrapper}>
          <Dropdown
            className={styles.dropdown}
            classDropdownHead={styles.dropdownHead}
            value={activeTab}
            setValue={setActiveTab}
            options={options}
          />

          <form onSubmit={formSubmitHandler}>
            <div className={styles.list}>
              <div
                className={cn(styles.item, {
                  [styles.active]: activeTab === options[0],
                })}
              >
                <div className={styles.anchor} ref={scrollToProfile}></div>
                <ProfileInformation values={values} setValues={setValues} />
              </div>

              <div
                className={cn(styles.item, {
                  [styles.active]: activeTab === options[2],
                })}
              >
                <div
                  className={styles.anchor}
                  ref={scrollToNotifications}
                ></div>

                <OtherInfo values={values} setValues={setValues} />
              </div>
              <div
                className={cn(styles.item, {
                  [styles.active]: activeTab === options[3],
                })}
              >
                <div className={styles.anchor} ref={scrollToPayment}></div>
                <Payment values={values} setValues={setValues} />
              </div>
            </div>
            <button
              type="submit"
              className={cn("button", styles.button, "mb-20")}
            >
              Save
            </button>
          </form>

          <div
            className={cn(styles.item, {
              [styles.active]: activeTab === options[2],
            })}
          >
            <div
              className={cn(styles.item, {
                [styles.active]: activeTab === options[1],
              })}
            >
              <div className={styles.anchor} ref={scrollToLogin}></div>
            </div>
          </div>
          <div
            className={cn(styles.item, {
              [styles.active]: activeTab === options[1],
            })}
          >
            <div className={styles.anchor} ref={scrollToLogin}></div>
            <Login values={values} setValues={setValues} />
          </div>
        </div>
      </div>
      <TooltipGlodal />
    </>
  );
};

export default Settings;
