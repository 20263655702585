import React, { useEffect, useMemo, useState } from "react";
import styles from "./Earning.module.sass";
import TooltipGlodal from "../../components/TooltipGlodal";
import Overview from "./Overview";
import ProductSales from "./ProductSales";
import Utilities from "./Utilities";
import Table from "./CustomTable/Table";
import { expensesOverviewData } from "./data";
import Card from "../../components/Card";
import { getRecentMonthOptions } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchExpenseOverview,
  selectExpenseLoader,
} from "../../redux/expensesSlice";
import CustomDropdown from "../../components/Dropdown/CustomDropdown";
import "./index.sass";
import Loader from "../../components/Loader";

const Expenses = () => {
  const monthOptions = useMemo(() => getRecentMonthOptions(6), [])?.reverse();
  const [values, setValues] = useState({
    filter_month: monthOptions[0],
  });

  const dispatch = useDispatch();
  const fetchOverviewData = (month) => {
    dispatch(fetchExpenseOverview(month));
  };
  // useEffect(() => {
  //   fetchOverviewData(monthOptions[monthOptions.length - 1]?.value);
  // }, []);

  useEffect(() => {
    if (values?.filter_month) {
      fetchOverviewData(values?.filter_month?.value);
    }
  }, [values]);

  const isLoading = useSelector(selectExpenseLoader);

  return (
    <>
      {isLoading && <Loader />}
      <div className={"drop_cont"}>
        <div></div>
        <CustomDropdown
          values={values}
          setValues={setValues}
          options={monthOptions}
          name="filter_month"
          placeholder="Select Month"
          required
        />
      </div>
      <div className={styles.section}>
        <Overview cardsData={expensesOverviewData} className={styles.card} />
        <div className={styles.row}>
          <div className={styles.col}>
            <ProductSales />
          </div>
          <div className={styles.col}>
            <Utilities className={styles.overflow_cont} />
          </div>
        </div>
        <Card>
          <Table />
        </Card>
      </div>
      <TooltipGlodal />
    </>
  );
};

export default Expenses;
