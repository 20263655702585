export const UDP_TYPE = "UDP_TYPE";
export const LAUNCHED_TYPE = "LAUNCHED_TYPE";

export const userSideNav = [
  // {
  //   title: "Home",
  //   icon: "home",
  //   url: "/",
  // },
  {
    title: "Projects",
    slug: "projects",
    icon: "diamond",
    dropdown: [
      {
        title: "Traffic Report",
        url: "/projects/traffic-report",
      },
      {
        title: "Under Development",
        url: "/projects/under-development",
        counter: "0",
        colorCounter: "#FFBC99",
        type: UDP_TYPE,
      },
      {
        title: "Released",
        url: "/projects/released",
        counter: "0",
        colorCounter: "#83BF6E",
        type: LAUNCHED_TYPE,
      },
    ],
  },
  {
    title: "Expenses",
    slug: "expenses",
    icon: "pie-chart",
    dropdown: [
      {
        title: "Overview",
        url: "/expenses/overview",
      },
      {
        title: "Hosting Server",
        url: "/expenses/hosting-server",
      },
      {
        title: "Api",
        url: "/expenses/api",
      },
      {
        title: "Theme",
        url: "/expenses/theme",
      },
      {
        title: "Plugin",
        url: "/expenses/plugin",
      },
      {
        title: "Software",
        url: "/expenses/software",
      },
    ],
  },
];

export const adminSideNav = [
  {
    title: "Home",
    icon: "home",
    url: "/",
  },
  {
    title: "Projects",
    slug: "projects",
    icon: "diamond",
    add: true,
    add_url: "/projects/add",
    dropdown: [
      {
        title: "Traffic Report",
        url: "/projects/traffic-report",
      },
      {
        title: "Under Development",
        url: "/projects/under-development",
        counter: "0",
        colorCounter: "#FFBC99",
        type: UDP_TYPE,
      },
      {
        title: "Released",
        url: "/projects/released",
        counter: "0",
        colorCounter: "#83BF6E",
        type: LAUNCHED_TYPE,
      },
    ],
  },
  {
    title: "Expenses",
    slug: "expenses",
    icon: "pie-chart",
    dropdown: [
      {
        title: "Overview",
        url: "/expenses/overview",
      },
      {
        title: "Hosting Server",
        url: "/expenses/hosting-server",
      },
      {
        title: "Api",
        url: "/expenses/api",
      },
      {
        title: "Theme",
        url: "/expenses/theme",
      },
      {
        title: "Plugin",
        url: "/expenses/plugin",
      },
      {
        title: "Software",
        url: "/expenses/software",
      },
    ],
  },
  {
    title: "Staffs",
    slug: "customers",
    icon: "profile-circle",
    add: true,
    add_url: "/customers/add",
    dropdown: [
      {
        title: "Overview",
        url: "/customers/overview",
      },
      {
        title: "Staff list",
        url: "/customers/customer-list",
      },
      {
        title: "Salary Claim Status",
        url: "/customers/claim-status",
      },
    ],
  },
  {
    title: "Monthly Report",
    slug: "report",
    icon: "pie-chart",
    dropdown: [
      {
        title: "Generate Report",
        url: "/report/cost-generate",
      },
      {
        title: "Report History",
        url: "/report/report-history",
      },
    ],
  },
];

// export const navigation = [
//     {
//       title: "Home",
//       icon: "home",
//       url: "/",
//     },
//     {
//       title: "Products",
//       slug: "products",
//       icon: "diamond",
//       add: true,
//       dropdown: [
//         {
//           title: "Dashboard",
//           url: "/products/dashboard",
//         },
//         {
//           title: "Drafts",
//           url: "/products/drafts",
//           counter: "2",
//           colorCounter: "#FFBC99",
//         },
//         {
//           title: "Released",
//           url: "/products/released",
//         },
//         {
//           title: "Comments",
//           url: "/products/comments",
//         },
//         {
//           title: "Scheduled",
//           url: "/products/scheduled",
//           counter: "8",
//           colorCounter: "#B5E4CA",
//         },
//       ],
//     },
//     {
//       title: "Customers",
//       slug: "customers",
//       icon: "profile-circle",
//       dropdown: [
//         {
//           title: "Overview",
//           url: "/customers/overview",
//         },
//         {
//           title: "Customer list",
//           url: "/customers/customer-list",
//         },
//       ],
//     },
//     {
//       title: "Shop",
//       icon: "store",
//       url: "/shop",
//     },
//     {
//       title: "Income",
//       slug: "income",
//       icon: "pie-chart",
//       dropdown: [
//         {
//           title: "Earning",
//           url: "/expenses/overview",
//         },
//         {
//           title: "Refunds",
//           url: "/income/refunds",
//         },
//         {
//           title: "Payouts",
//           url: "/expenses/hosting-server",
//         },
//         {
//           title: "Statements",
//           url: "/income/statements",
//         },
//       ],
//     },
//     {
//       title: "Promote",
//       icon: "promotion",
//       url: "/promote",
//     },
//   ];
