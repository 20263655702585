import { configureStore } from "@reduxjs/toolkit";
import projectReducer from "./projectSlice";
import staffReducer from "./staffSlice";
import userReducer from "./userSlice";
import expensesReducer from "./expensesSlice";
import gaReducer from "./gaSubmissionSlice";
import salaryClaimReducer from "./salaryClaimSlice";
import reportReducer from "./reportSlice";
import dashboardReducer from "./dashboardSlice";

export const store = configureStore({
  reducer: {
    projects: projectReducer,
    staff: staffReducer,
    user: userReducer,
    expenses: expensesReducer,
    ga: gaReducer,
    salaryClaims: salaryClaimReducer,
    reports: reportReducer,
    dashboard : dashboardReducer
  },
});
