export const  items = [
    {
      menu: [
        {
          title: "Profile",
          url: "/profile",
        },
        {
          title: "Edit profile",
          url: "/settings",
        },
      ],
    },
    // {
    //   menu: [
    //     {
    //       title: "Analytics",
    //       icon: "bar-chart",
    //       url: "/customers/overview",
    //     },
    //     {
    //       title: "Personal Affiliate",
    //       icon: "ticket",
    //       url: "/affiliate-center",
    //     },
    //     {
    //       title: "Explore creators",
    //       icon: "grid",
    //       url: "/explore-creators",
    //     },
    //   ],
    // },
    {
      menu: [
        {
          title: "Claim My Salary",
          icon: "leaderboard",
          color: true,
        },
      ],
    },
    {
      menu: [
        {
          title: "Log out",
        },
      ],
    },
  ];
  