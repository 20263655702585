import React, { useMemo, useState } from "react";
import styles from "./TrafficChannel.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Dropdown from "../../../components/Dropdown";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import useDarkMode from "use-dark-mode";
import { useSelector } from "react-redux";
import { staffOverviewSelector } from "../../../redux/staffSlice";
import { getMonthFromDate, groupArrayByKey } from "../../../utils";
import { postionType } from "../../../screens/Settings/ProfileInformation/data";
import { ProjectCategory } from "../../NewProject/data";
import { dashboardSelector } from "../../../redux/dashboardSlice";

const intervals = ["Last 7 days", "This month", "All time"];

const legend = [
  {
    title: "Listing",
    color: "#2A85FF",
  },
  {
    title: "Forum",
    color: "#B1E5FC",
  },
  {
    title: "News",
    color: "#FFBC99",
  },
  {
    title: "Live Score",
    color: "#FFCC99",
  },

  {
    title: "Landing Page",
    color: "#CABDFF",
  },
  {
    title: "Video",
    color: "#FFD88D",
  },
  {
    title: "Other",
    color: "#FFD28D",
  },
];


const TrafficChannel = ({ className }) => {
  const darkMode = useDarkMode(false);
  const [sorting, setSorting] = useState(intervals[0]);
  const dashboardData = useSelector(dashboardSelector);


  const new_data = useMemo(() => {
    if(!dashboardData) return
    let projects_group_category_month = dashboardData.projects_group_category_month;
    let group_by_month  = groupArrayByKey(projects_group_category_month, "month_year");


    let format = Object.keys(group_by_month)?.map((month) => {
      let monthly_project = group_by_month[month];
      const groupbyCategory = groupArrayByKey(monthly_project, "category");
      console.log(groupbyCategory, "group by position");
      return {
        name: getMonthFromDate(month),
        [ProjectCategory.Listing]: groupbyCategory[ProjectCategory.Listing]?.length || 0,
        [ProjectCategory.Forum]: groupbyCategory[ProjectCategory.Forum]?.length || 0,
        [ProjectCategory.News]: groupbyCategory[ProjectCategory.News]?.length || 0,
        [ProjectCategory.Live_score]: groupbyCategory[ProjectCategory.Live_score]?.length || 0,
        [ProjectCategory.Landing_page]: groupbyCategory[ProjectCategory.Landing_page]?.length || 0,
        [ProjectCategory.Video]: groupbyCategory[ProjectCategory.Video]?.length || 0,
        [ProjectCategory.Other]: groupbyCategory[postionType.Other]?.length || 0,
      };
    });
    return format;
  }, [dashboardData]);


  return (
    <Card
      className={cn(styles.card, className)}
      title="Project Type"
      classTitle="title-purple"
      // head={
      //   <Dropdown
      //     className={styles.dropdown}
      //     classDropdownHead={styles.dropdownHead}
      //     value={sorting}
      //     setValue={setSorting}
      //     options={intervals}
      //     small
      //   />
      // }
    >
      <div className={styles.chart}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={new_data}
            margin={{
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
            }}
            barSize={46}
            barGap={8}
          >
            <CartesianGrid
              strokeDasharray="none"
              stroke={darkMode.value ? "#272B30" : "#EFEFEF"}
              vertical={false}
            />
            <XAxis
              dataKey="name"
              axisLine={false}
              tickLine={false}
              tick={{ fontSize: 12, fontWeight: "500", fill: "#6F767E" }}
              padding={{ left: 10 }}
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              tick={{ fontSize: 12, fontWeight: "500", fill: "#6F767E" }}
            />
            <Tooltip
              contentStyle={{
                backgroundColor: "#272B30",
                borderColor: "rgba(255, 255, 255, 0.12)",
                borderRadius: 8,
                boxShadow:
                  "0px 4px 8px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1), inset 0px 0px 1px #000000",
              }}
              labelStyle={{ fontSize: 12, fontWeight: "500", color: "#fff" }}
              itemStyle={{
                padding: 0,
                textTransform: "capitalize",
                fontSize: 12,
                fontWeight: "600",
                color: "#fff",
              }}
              cursor={{ fill: "#f3f2f3" }}
            />
            <Bar dataKey={ProjectCategory.Listing} stackId="a" fill="#2A85FF" />
            <Bar dataKey={ProjectCategory.Forum} stackId="a" fill="#B1E5FC" />
            <Bar dataKey={ProjectCategory.News} stackId="a" fill="#FFBC99" />
            <Bar dataKey={ProjectCategory.Live_score} stackId="a" fill="#FFCC99" />
            <Bar dataKey={ProjectCategory.Landing_page} stackId="a" fill="#CABDFF" />
            <Bar dataKey={ProjectCategory.Video} stackId="a" fill="#FFD88D" />
            <Bar dataKey={ProjectCategory.Other} stackId="a" fill="#FFD28D" />
          </BarChart>
        </ResponsiveContainer>
      </div>
      <div className={styles.legend}>
        {legend.map((x, index) => (
          <div className={styles.indicator} key={index}>
            <div
              className={styles.color}
              style={{ backgroundColor: x.color }}
            ></div>
            {x.title}
          </div>
        ))}
      </div>
    </Card>
  );
};

export default TrafficChannel;
