import React, { useState } from "react";
import "./HistoryItem.scss";
import TeamsSection from "./TeamSection/TeamsSection";
import ExpenseSection from "./ExpenseSection/ExpenseSection";

const HistoryItem = ({ projectInfo ,month }) => {
  const [showDetail, setShowDetail] = useState(false);
  return (
    <div>
      <>
        <div className="flex align-center justify-between">
          <h2>{projectInfo?.title}</h2>
          <button onClick={() => setShowDetail((state) => !state)}>
            {showDetail ? "Hide Detail" : "Show Detail"}
          </button>
        </div>
        <TeamsSection
          showDetail={showDetail}
          projectInfo={projectInfo}
          teams={projectInfo?.teams}
          month={month}
        />
        <ExpenseSection showDetail={showDetail} projectInfo={projectInfo} />
      </>
    </div>
  );
};

export default HistoryItem;
