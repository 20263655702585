import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "./axiosInstance";

const initialState = {
  data: null,
  isLoading: false,
  project_based: null,
  traffic_overview : null
};

export const fetchGaByProjectId = createAsyncThunk(
  "fetch ga by project",
  (projectId, { rejectWithValue }) => {
    return Axios.get(`/gaSubmissions?project_id=${projectId}`).then(
      (res) => res.data
    );
  }
);
export const fetchAllGaSubmissions = createAsyncThunk(
  "fetch all Ga submissions",
  (queryString, { rejectWithValue }) => {
    return Axios.get(`/gaSubmissions`).then((res) => res.data);
  }
);
export const fetchTrafficOverview = createAsyncThunk(
  "fetch Traffic Overview",
  (queryString, { rejectWithValue }) => {
    return Axios.get(`/trafic-overview`).then((res) => res.data);
  }
);

export const GaSlice = createSlice({
  name: "ga submission slice",
  initialState,
  reducers: {
    clearProjectBasedData: (state) => {
      state.project_based = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllGaSubmissions.pending, (state) => {
      if (!state.data) state.isLoading = true;
    });
    builder.addCase(fetchAllGaSubmissions.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload?.data;
    });
    builder.addCase(fetchAllGaSubmissions.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    // FETCH GA SUBMISSIONS BY PROJECT ID
    builder.addCase(fetchGaByProjectId.pending, (state) => {
      if (!state.data) state.isLoading = true;
    });
    builder.addCase(fetchGaByProjectId.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload?.data;
    });
    builder.addCase(fetchGaByProjectId.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
    // FETCH TRAFFIC OVERVIEW 
    builder.addCase(fetchTrafficOverview.pending, (state) => {
      if (!state.data) state.isLoading = true;
    });
    builder.addCase(fetchTrafficOverview.fulfilled, (state, action) => {
      state.isLoading = false;
      state.traffic_overview = action.payload?.data;
    });
    builder.addCase(fetchTrafficOverview.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export default GaSlice.reducer;

/// SELECTORS
export const selectGaLoader = (store) => store.ga.isLoading;
export const selectGaSubmissions = (store) => store.ga.data;
export const selectGaSubmissionByProject = (store) => store.ga.project_based;
export const selectTrafficOverview = (store) => store.ga.traffic_overview;

export const { clearProjectBasedData } = GaSlice.actions;
