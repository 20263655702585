import React, { useEffect, useMemo, useState } from "react";
import styles from "./ProductActivity.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Dropdown from "../../../components/Dropdown";
import Item from "./Item";
import { generalReportColumns, reportData } from "./data";
import Tooltip from "../../../components/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/Loader";
import {
  ProjectSlectorWithAflliateAndGaGroup,
  fetchAllProjects,
  projectSelector,
} from "../../../redux/projectSlice";
import { divide, getMonthFromDate } from "../../../utils";
import CustomDropdown from "../../../components/Dropdown/CustomDropdown";

const ProductActivity = () => {
  const intervals = ["All"];
  let defaultOption =  {
    label: "All",
    value: "All",
  }
  const [projectsFilter, setProjectsFilter] = useState([]);
  const [values, setValues] = useState({
    filter:defaultOption,
  });

  const [activeTab, setActiveTab] = useState(intervals[0]);
  const projects = useSelector(projectSelector);
  const groupedProjects = useSelector(ProjectSlectorWithAflliateAndGaGroup);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!projects?.data?.length) dispatch(fetchAllProjects());
  }, []);

  const filterOptions = useMemo(() => {
    let options = groupedProjects?.map((each) => ({
      label: each.code,
      value: each.code,
    }));
    return [defaultOption, ...(options || [])];
  }, [groupedProjects]);

  const dropdownChangeHandler = (project_code) => {
    setActiveTab(project_code);
    if (project_code === "All") {
      setProjectsFilter(groupedProjects);
      return;
    }
    let filterProjects = groupedProjects?.filter(
      (each) => each.code === project_code
    );
    setProjectsFilter(filterProjects);
  };

  useEffect(()=>{
    dropdownChangeHandler(values?.filter?.value)
  },[values])

  useEffect(() => {
    if (groupedProjects) {
      setProjectsFilter(groupedProjects);
    }
  }, [groupedProjects]);

  console.log(projectsFilter, "project fileter");

  return (
    <>
      {projects.isLoading && <Loader className="page_loader" />}
      <Card
        className={styles.card}
        title="General Report"
        classTitle="title-green"
        head={
          <CustomDropdown
            values={values}
            setValues={setValues}
            options={filterOptions}
            name="filter"
            required
          />
        }
      >
        <div className={styles.table}>
          <div className={styles.row}>
            {generalReportColumns?.map((x) => (
              <div className={styles.col}>
                {x?.label}
                <Tooltip
                  className={styles.tooltip}
                  title={x.tooltip || ""}
                  icon="info"
                  place="right"
                />
              </div>
            ))}
          </div>
          {projectsFilter?.map((project) => {
            if (!project?.group_report_month?.length) return null;
            return project?.group_report_month?.map((x, index) => (
              <div className={styles.row} key={index}>
                <div className={styles.col}>
                  <div className={styles.label}>Period</div>
                  {getMonthFromDate(x.report_month)}
                </div>
                <div className={styles.col}>
                  <div className={styles.label}>Project</div>
                  <Item
                    className={styles.item}
                    value={project.code}
                    color="#B5E4CA"
                  />
                </div>
                <div className={styles.col}>
                  <div className={styles.label}>Registration</div>
                  <Item
                    className={styles.item}
                    value={x.affiliate?.registrations || 0}
                    color="#CABDFF"
                  />
                </div>
                <div className={styles.col}>
                  <div className={styles.label}>Session</div>
                  <Item
                    className={styles.item}
                    value={x.ga_submission?.session || 0}
                    color="#B1E5FC"
                  />
                </div>
                <div className={styles.col}>
                  <div className={styles.label}>Staff</div>
                  <Item
                    className={styles.item}
                    value={project.teams?.length || 0}
                    color="#CABDFF"
                  />
                </div>
                <div className={styles.col}>
                  <div className={styles.label}>SPR</div>
                  <Item
                    className={styles.item}
                    value={divide(
                      x.ga_submission?.session,
                      x.affiliate?.registrations
                    )}
                    color="#B5E4CA"
                  />
                </div>
                <div className={styles.col}>
                  <div className={styles.label}>CPS</div>
                  <Item
                    className={styles.item}
                    value={divide(
                      project.project_costs_sum_project_cost /
                        project?.project_costs?.length,
                      x.ga_submission?.session,
                      true
                    )}
                    color="#FFD88D"
                  />
                </div>
                <div className={styles.col}>
                  <div className={styles.label}>CPR</div>
                  <Item
                    className={styles.item}
                    value={divide(
                      project.project_costs_sum_project_cost /
                        project?.project_costs?.length,
                      x.affiliate?.registrations,
                      true
                    )}
                    color="#FFD88D"
                  />
                </div>
              </div>
            ));
          })}
          {/* {reportData.map((x, index) => (
            <div className={styles.row} key={index}>
              <div className={styles.col}>
                <div className={styles.label}>Period</div>
                {x.title}
              </div>
              <div className={styles.col}>
                <div className={styles.label}>Project</div>
                <Item className={styles.item} item={x.project} />
              </div>
              <div className={styles.col}>
                <div className={styles.label}>Registration</div>
                <Item className={styles.item} item={x.registrations} />
              </div>
              <div className={styles.col}>
                <div className={styles.label}>Session</div>
                <Item className={styles.item} item={x.session} />
              </div>
              <div className={styles.col}>
                <div className={styles.label}>Staff</div>
                <Item className={styles.item} item={x.staff} />
              </div>
              <div className={styles.col}>
                <div className={styles.label}>SPR</div>
                <Item className={styles.item} item={x.spr} />
              </div>
              <div className={styles.col}>
                <div className={styles.label}>CPS</div>
                <Item className={styles.item} item={x.cps} />
              </div>
              <div className={styles.col}>
                <div className={styles.label}>CPR</div>
                <Item className={styles.item} item={x.cpr} />
              </div>
            </div>
          ))} */}
        </div>
        {/* <div className={styles.nav}>
          {intervals.map((x, index) => (
            <button
              className={cn(styles.link, {
                [styles.active]: x === activeTab,
              })}
              onClick={() => setActiveTab(x)}
              key={index}
            >
              {x}
            </button>
          ))}
        </div> */}
      </Card>
    </>
  );
};

export default ProductActivity;
