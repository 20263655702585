import React, { useMemo } from "react";
import { contributionTeamInfo } from "../data";
import { getMonthFromDate } from "../../../../utils";

export const cpGenerate = (project_teams, project_id) => {
  let totalCP = 0;
  let thisProjectCp = 0;
  project_teams?.forEach((each) => {
    totalCP += contributionTeamInfo[each.role_id]?.cp || 0;
    if (each.project_id == project_id) {
      thisProjectCp += contributionTeamInfo[each.role_id]?.cp || 0;
    }
  });
  return { total: totalCP, project: thisProjectCp };
};

const UserProjects = ({
  project_teams,
  project_id,
  showDetail,
  latest_salary_submission,
  month
}) => {
  const cp = useMemo(() => {
    return cpGenerate(project_teams, project_id);
  }, [project_teams, project_id]);

  const salary_amount = useMemo(() => {
    if (!latest_salary_submission) return 0;
    if(month && getMonthFromDate(month) != getMonthFromDate(latest_salary_submission?.claim_from)  ){
         return 0 ;
    }
    let claim_amount = +latest_salary_submission?.claim_amount;
    let claim_locked_fund = +latest_salary_submission?.claim_locked_fund;
    let deduct_debit = +latest_salary_submission?.deduct_debit;
    return claim_amount + claim_locked_fund - deduct_debit;
  }, [latest_salary_submission,month]);

  const billedCostGenerator = useMemo(() => {
    return ((cp.project / cp.total) * salary_amount).toFixed(2);
  }, [cp, salary_amount]);

  return (
    <>
      {showDetail && (
        <>
          {showDetail && (
            <>
              <h4 className="row_data">
                Salary : ${salary_amount}
              </h4>
              <h4 className="row_data">Projects Contribution</h4>
            </>
          )}

          <ul>
            {project_teams?.map((team, i) => (
              <li
                className={`project ${
                  project_id == team.project.id && " highlight"
                }`}
              >{`${i + 1} . ${team?.project.title} ( ${
                contributionTeamInfo[team?.role_id]?.label
              } - ${contributionTeamInfo[team?.role_id]?.cp})`}</li>
            ))}
          </ul>
          <h4 className="billed row_data">Total CP : {cp.total}</h4>
        </>
      )}

      <h4 className="billed row_data">
        Billed Cost : $
        <span className={`cost-${project_id}`}>{billedCostGenerator}</span>
      </h4>
    </>
  );
};

export default UserProjects;
