
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Axios from './axiosInstance';

const initialState = {
    data: null,
    isLoading: false,
    detail: null,
    error: null
}

export const fetchAllReportHistory = createAsyncThunk('/api/project-cost-save', (queryString, { rejectWithValue }) => {
    return Axios.get(`/project-cost-save`).then(res => res.data)
});


export const reportSlice = createSlice({
    name: 'reportHistory',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(fetchAllReportHistory.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchAllReportHistory.fulfilled, (state, action) => {
            state.isLoading = false
            state.data = action.payload?.data;
        })
        builder.addCase(fetchAllReportHistory.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error.message;
        })


    }
})

export default reportSlice.reducer;

/// SELECTORS
export const reportSelector = (store) => store.reports;







