import React from "react";
import cn from "classnames";
import styles from "./ImagesAndCTA.module.sass";
import Card from "../../../components/Card";
import File from "../../../components/File";


const ImagesAndCTA = ({ className, values, setValues }) => {

  return (
    <Card
      className={cn(styles.card, className)}
      title="Images"
      classTitle="title-blue"
    >
      <div
        className={styles.images}>
        <File
          className={styles.field}
          title="Click or drop image"
          label="Website HomePage"
          tooltip="Visit Your Website and take a screenshot of Home page . ** Preferred Landscape image"
          values={values}
          setValues={setValues}
          name="cover_image"
        />
        <File
          className={styles.field}
          title="Click or drop image"
          label="Logo or Favicon"
          tooltip="Preffered Square Image"
          values={values}
          setValues={setValues}
          name="logo"
        />
      </div>
    </Card>
  );
};

export default ImagesAndCTA;
