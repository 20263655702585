import React, { useState } from "react";
import cn from "classnames";
import styles from "./Login.module.sass";
import Item from "../Item";
import TextInput from "../../../components/TextInput";
import { toast } from "react-toastify";
import UseApiCalling from "../../../hooks/UseApiCalling";
import Loader from "../../../components/Loader";

const Login = ({ className }) => {
  const [values, setValues] = useState({});
  const { isLoading, callApi } = UseApiCalling();

  const cb = (response, isSuccess) => {
    if (isSuccess) {}
  };

  const updatePasswordHandler = (e) => {
    e.preventDefault();
    if (values?.new_password != values?.confirm_password) {
      toast.error("New password and Confirm password is not the same");
      return;
    }
    callApi("update-password", values, "put", cb);
  };

  return (
    <Item
      className={cn(styles.card, className)}
      title="Login"
      classTitle="title-purple"
    >
      {isLoading && <Loader />}
      <form onSubmit={updatePasswordHandler} className={styles.fieldset}>
        <TextInput
          className={styles.field}
          label="Old password"
          name="current_password"
          type="text"
          required
          values={values}
          setValues={setValues}
        />
        <div className={styles.row}>
          <TextInput
            className={styles.field}
            label="New password"
            name="new_password"
            type="text"
            required
            values={values}
            setValues={setValues}
          />
          <TextInput
            className={styles.field}
            label="Confirm new password"
            name="confirm_password"
            type="password"
            required
            values={values}
            setValues={setValues}
          />
        </div>
        <button className={cn("button-stroke", styles.button)}>
          Update password
        </button>
      </form>
    </Item>
  );
};

export default Login;
