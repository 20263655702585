
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Axios from './axiosInstance';

const initialState = {
    data: null,
    isLoading: false,
    detail: null,
    error: null,
    registrations : null
}

export const fetchAllDashbaordInfo = createAsyncThunk('/api/home-overview', (queryString, { rejectWithValue }) => {
    return Axios.get(`/home-overview`).then(res => res.data)
});
export const fetchDashboardRegistrations = createAsyncThunk('/api/daily-registrations-overview', (queryString, { rejectWithValue }) => {
    return Axios.get(`/daily-registrations-overview`).then(res => res.data)
});


export const dashboardSlice = createSlice({
    name: 'dashboardSlice',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(fetchAllDashbaordInfo.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchAllDashbaordInfo.fulfilled, (state, action) => {
            state.isLoading = false
            state.data = action.payload?.data;
        })
        builder.addCase(fetchAllDashbaordInfo.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error.message;
        })

        /// DASHBOARD REGISTRATIONS

        builder.addCase(fetchDashboardRegistrations.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchDashboardRegistrations.fulfilled, (state, action) => {
            state.isLoading = false
            state.registrations = action.payload?.data;
        })
        builder.addCase(fetchDashboardRegistrations.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error.message;
        })


    }
})

export default dashboardSlice.reducer;

/// SELECTORS
export const dashboardSelector = (store) => store.dashboard?.data;
export const dashbaordRegistrationsSelector = (store) => store.dashboard?.registrations;
export const dashboardLoaderSelector = (store) => store.dashboard?.isLoading;







