import React from "react";
import styles from "./Table.module.sass";
import { columns } from "./data";
import { useSelector } from "react-redux";
import { selectExpensesOverview } from "../../../redux/expensesSlice";
import Row from "./Row";

const Table = () => {
  const overviewData = useSelector(selectExpensesOverview);

  return (
    <>
      <div className={styles.table}>
        <div className={styles.row}>
          {columns?.map((x) => (
            <div className={styles.col}>{x.header}</div>
          ))}
        </div>
        {overviewData?.cost_reports?.map((each) => (
          <Row
            data={each}
            afflaite_submissions={overviewData?.afflaite_submissions}
            ga_submissions={overviewData?.ga_submissions}
          />
        ))}
      </div>
    </>
  );
};

export default Table;
