export const contributionTeamInfo = {
  PRODUCT_MANAGER: {
    label: "Product Manager",
    cp: 10,
  },
  UI_UX: {
    label: "UI/UX",
    cp: 3,
  },
  FRONTEND: {
    label: "Frontend",
    cp: 5,
  },
  BACKEND: {
    label: "Backend",
    cp: 8,
  },
  BACKEND_BACKUP: {
    label: "Backend Backup",
    cp: 3,
  },
  WORDPRESS_TECHNICAL: {
    label: "Wordpress Technical",
    cp: 3,
  },
  GRAPHIC_DESIGNER: {
    label: "Graphic Designer",
    cp: 5,
  },
  QC_COMPLAIN: {
    label: "QC & Complain",
    cp: 2,
  },
  OFFPAGE_RESEARCH: {
    label: "Offpage research",
    cp: 10,
  },
  COMPETITOR_RESEARCH: {
    label: "Competitor Research",
    cp: 2,
  },
  INPAGE_SEO: {
    label: "Inpage SEO",
    cp: 5,
  },
  CONTENT_1: {
    label: "Content 1 (Lead)",
    cp: 5,
  },
  CONTENT_2: {
    label: "Content 2",
    cp: 3,
  },
  CONTENT_3: {
    label: "Content 3",
    cp: 3,
  },
  CONTENT_4: {
    label: "Content 4",
    cp: 3,
  },
  CONTENT_5: {
    label: "Content 5",
    cp: 3,
  },
  FAKE_COMMENT: {
    label: "Fake Comment (Web & Social)",
    cp: 2,
  },
  SOCIAL_MEDIA: {
    label: "Social Media",
    cp: 10,
  },
};
