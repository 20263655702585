import React, { useEffect, useMemo } from "react";
import styles from "./Payouts.module.sass";
import TooltipGlodal from "../../components/TooltipGlodal";
import Table from "./Table";
import Overview from "../Expenses/Overview";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllExpences,
  selectExpenseLoader,
  selectExpensesApi,
} from "../../redux/expensesSlice";
import { numberWithCommas } from "../../utils";

const ApiPage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAllExpences("api"));
  }, [dispatch]);

  const apiExpenses = useSelector(selectExpensesApi);
  const loading = useSelector(selectExpenseLoader);
  const formattedData = useMemo(() => {
    let totalProjects = 0;
    let updatedData = apiExpenses?.map((each) => {
      let update = { ...each };
      let project_count = each?.projects?.length;
      totalProjects += project_count;
      update.project_count = project_count;
      let monthly_cost_per_web =
        each.amount / (each.months * (project_count || 1));
      let cost_per_year = (each.amount / each.months) * 12;
      monthly_cost_per_web = numberWithCommas(monthly_cost_per_web?.toFixed(2));
      cost_per_year = numberWithCommas(cost_per_year?.toFixed(2));
      update.cost_per_month = monthly_cost_per_web;
      update.cost_per_year = cost_per_year;
      update.cost_per_website = each?.amount / (each?.projects?.length || 1);
      return update;
    });
    let total_subscription_fee = 0;
    let total_subscription_fee_this_year = 0;
    const currentYear = new Date().getFullYear();
    updatedData?.forEach((data) => {
      total_subscription_fee += data?.amount;
      const purchaseYear = new Date(data.purchase_date).getFullYear();
      if (purchaseYear === currentYear) {
        total_subscription_fee_this_year += data?.amount;
      }
    });
    let total_cost_per_website = total_subscription_fee / (totalProjects || 1);
    total_subscription_fee = numberWithCommas(total_subscription_fee);
    total_cost_per_website = numberWithCommas(total_cost_per_website);
    total_cost_per_website = numberWithCommas(total_cost_per_website);
    return {
      data: updatedData,
      total_subscription_fee,
      total_subscription_fee_this_year,
      total_cost_per_website,
    };
  }, [apiExpenses]);
  const data = {
    total: {
      value: 37.8,
      counter: `$${formattedData.total_subscription_fee}`,
    },
    yearly: {
      value: 37.8,
      counter: `$${formattedData.total_subscription_fee_this_year}`,
    },
    website: {
      value: -20.8,
      counter: `$${formattedData.total_cost_per_website}`,
    },
  };
  return (
    <>
      <div className={styles.section}>
        <Overview
          hideIncrement
          cardsData={hostingServerOverviewCards}
          data={data}
          className={styles.card}
        />
        <Table data={formattedData.data} loading={loading} />
      </div>
      <TooltipGlodal />
    </>
  );
};

export default ApiPage;

export const hostingServerOverviewCards = [
  {
    type: "total",
    title: "Total Subscribes Fee",
    icon: "activity",
    color: "#B5E4CA",
    tooltip: "Small description Earning",
  },
  {
    type: "yearly",
    title: "New Subscribes this year",
    icon: "pie-chart",
    color: "#CABDFF",
    tooltip: "Small description Balance",
  },
  {
    type: "website",
    title: "Cost per website",
    icon: "shopping-bag",
    color: "#B1E5FC",
    tooltip: "Small description Total",
  },
];
