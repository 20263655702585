import React, { useEffect, useMemo, useState } from "react";
import "./CostGenerate.sass";
import UseApiCalling from "../../hooks/UseApiCalling";
import Loader from "../../components/Loader";
import HistoryItem from "./HistoryItem/HistoryItem";
import "./CostGenerate.sass";
import ProjectCost from "./ProjectCost";
import {
  dateFormatter,
  dateFormatter2,
  getEndMonth,
  getMonthFromDate,
  getRecentMonthOptions,
} from "../../utils";
import CustomDropdown from "../../components/Dropdown/CustomDropdown";
import Card from "../../components/Card";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllSalaryClaims,
  selectSalaryClaims,
} from "../../redux/salaryClaimSlice";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const CostGenerate = () => {
  const [projectInfo, setProjectInfo] = useState(null);
  const [totalCost, setTotalCost] = useState(0);
  const [lastReport, setLastReport] = useState(null);
  const { isLoading, callApi } = UseApiCalling();
  const [values, setValues] = useState({});
  const monthOptions = useMemo(() => getRecentMonthOptions(2), []);

  const cb = (res, isSuccess) => {
    if (isSuccess) {
      setProjectInfo(res.data);
    }
  };

  const generateButtonClick = () => {
    callApi(`/project-cost`, null, "get", cb, false);
  };

  useEffect(() => {
    setTimeout(() => {
      let sum = 0;
      const costs = document.querySelectorAll(`.project_cost`);
      for (let i = 0; i < costs.length; i++) {
        let cost = +costs[i]?.innerHTML;
        sum += cost;
      }
      setTotalCost(sum?.toFixed(2));
    }, 1200);
  }, [projectInfo, values?.month]);

  const cb2 = (res, isSuccess) => {
    if (isSuccess) {
      setLastReport(res.data);
    }
  };
  useEffect(() => {
    callApi(`/last-project-cost-report`, null, "get", cb2, false);
  }, []);

  const history = useHistory();
  const cb3 = (res, isSuccess) => {
    if (isSuccess) {
      history.push("/report/report-history");
    }
  };

  const claimData = useSelector(selectSalaryClaims);
  const unclaimedUsers = useMemo(() => {
    if (!values?.month?.value || !projectInfo) return null;
    const filterUsers = claimData?.data?.filter((user) => {
      let latest_salary_submission = user?.latest_salary_submission;
      if (!latest_salary_submission?.claim_from) return true;
      let month = getMonthFromDate(latest_salary_submission?.claim_from);
      let generate_month = getMonthFromDate(values?.month?.value);
      if (
        month == generate_month &&
        latest_salary_submission?.status == "approved"
      )
        return false;
    });
    return filterUsers;
  }, [claimData, values?.month]);

  const saveReportHandler = () => {
    let report_from = values?.month.value;
    let report_to = getEndMonth(report_from);
    let projectCosts = [];
    const costs = document.querySelectorAll(`.project_cost`);
    const labour_costs = document.querySelectorAll(`.labour_cost`);
    const expense_costs = document.querySelectorAll(`.expense_cost`);
    for (let i = 0; i < costs.length; i++) {
      let project_cost = +costs[i]?.innerHTML;
      let project_id = costs[i]?.getAttribute("project_id");
      let labour_cost = +labour_costs[i]?.innerHTML;
      let expense_cost = +expense_costs[i]?.innerHTML;
      projectCosts.push({
        project_cost,
        labour_spending : labour_cost,
        expense_spending : expense_cost,
        project_id,
      });
    }
    let expense_spending = 0;
    let labour_spending = 0;
    projectCosts.forEach((each) => {
      expense_spending += each.expense_spending;
      labour_spending += each.labour_spending;
    });
    let launched_projects_count  = projectInfo?.filter(each=>each.is_project_launched=="true")?.length;
    let progress_projects_count = projectInfo?.length - launched_projects_count;
    let payload = {
      report_summary: JSON.stringify(projectInfo),
      report_from,
      report_to,
      total_amount: parseInt(totalCost),
      project_cost: projectCosts,
      expense_spending: parseInt(expense_spending),
      labour_spending: parseInt(labour_spending),
      launched_projects_count ,
      progress_projects_count
    };
    console.log(payload, "payload");
     callApi(`/project-cost-save`, payload, "post", cb3);
  };

  const saveReport = (e) => {
    e?.preventDefault();
    if (unclaimedUsers?.length) {
      if (
        window.confirm(
          `are you sure to generate the report below users are not yet claimed the salare`
        )
      ) {
        saveReportHandler();
      }
    } else {
      saveReportHandler();
    }
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAllSalaryClaims());
  }, []);

  console.log(projectInfo,'project info')
  return (
    <div className="cost_generate_root">
      {isLoading && <Loader />}
      <p className="mb-20">
        Last Generated Report :{" "}
        {!lastReport
          ? "No report"
          : dateFormatter2(lastReport?.report_to || "")}
      </p>

      <button onClick={generateButtonClick} className="button mb-20">
        Generate Cost
      </button>
      {projectInfo && (
        <Card className="mb-20">
          <h2>Cost of this Report : ${totalCost}</h2>
          <form onSubmit={saveReport} className="mb-20 mt-20">
            <CustomDropdown
              label="Month"
              values={values}
              setValues={setValues}
              options={monthOptions}
              name="month"
              placeholder="Select Month"
              isClearable
              required
            />
            <button className="button mt-20">Save Report</button>
          </form>
          {unclaimedUsers && (
            <div className="alert_cont">
              <p className="alert_msg">
                The below Users not claimed on {values?.month?.label}
              </p>
              <div className="unclaimed_users_cont">
                {unclaimedUsers?.map((each) => (
                  <div className="user_cont">{each?.name}</div>
                ))}
              </div>
            </div>
          )}
        </Card>
      )}
      {projectInfo?.map((each) => (
        <ProjectCost month={values?.month?.value} project={each} />
      ))}
    </div>
  );
};

export default CostGenerate;
