import React, { useMemo, useState } from "react";
import cn from "classnames";
import styles from "./PopularProducts.module.sass";
import { Link } from "react-router-dom";
import Card from "../Card";
import ModalProduct from "../ModalProject";
import { useSelector } from "react-redux";
import { dashboardSelector } from "../../redux/dashboardSlice";
import { divide, sortByKey } from "../../utils";
import CustomImage from "../CustomImage/CustomImage";

const PopularProducts = ({ className, views }) => {
  const [visibleModalProduct, setVisibleModalProduct] = useState(false);
  //sortByKey
  const dashboardData = useSelector(dashboardSelector);

  const projects = useMemo(() => {
    let projects = dashboardData?.projects;
    let sortByRegitrations = sortByKey(
      projects,
      "affliates_sum_registrations",
      true
    );
    if (!projects || projects?.length < 10) return sortByRegitrations;
    else return sortByRegitrations.slice(0, 9);
  }, [dashboardData]);

  console.log(projects,'some projects');
  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Top 10 Registrations"
        classTitle="title-blue"
      >
        <div className={styles.popular}>
          <div className={styles.head}>
            <div className={styles.stage}>Projects</div>
            <div className={styles.stage}>Monthly Reg.</div>
          </div>
          <div className={styles.list}>
            {projects?.map(
              (x, index) =>
                views > index && (
                  <div
                    className={styles.item}
                    key={index}
                    // onClick={() => setVisibleModalProduct(true)}
                  >
                    <div className={styles.preview}>
                      <CustomImage
                        src={x.cover_image}
                        alt="Product"
                      />
                    </div>
                    <div className={styles.title}>{x.title}</div>
                    <div className={styles.details}>
                      <div className={styles.price}>{parseInt(divide(x.affliates_sum_registrations,x.affliates_count))||0}</div>
                    </div>
                  </div>
                )
            )}
          </div>
        </div>
      </Card>
      <ModalProduct
        visible={visibleModalProduct}
        onClose={() => setVisibleModalProduct(false)}
      />
    </>
  );
};

export default PopularProducts;
