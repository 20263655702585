import React from "react";
import Panel from "./Panel";
import Projects from "../TrafficReport/Projects";

const ProjectLisingPage = () => {
  return (
    <>
      <Projects />
      {/* <Panel /> */}
    </>
  );
};

export default ProjectLisingPage;
