

const dynamicDomain = () => {
    let baseUrl = 'http://jjseo.test';
    let hostname = window.location.origin;
    if (hostname?.includes('jjseo')) {
        baseUrl = "https://backend.jjseo.report"
    }
    else if (hostname?.includes('jeffria')) {
        baseUrl = "https://api.jeffria.com"
    }
    else if (hostname?.includes('astarvip')) {
        baseUrl = "https://backend.astarvip.com"
    }
    return baseUrl
}

export const BACKEND_URL = dynamicDomain()

export const APP_NAME = 'jjseo'