import React, { useState } from "react";
import styles from "./Products.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Form from "../../../components/Form";
import Table from "./Table";

import useDebounce from "../../../hooks/UseDebounce/UseDebounce";
import { useMemo } from "react";
import Loader from "../../../components/Loader";
import UseProjects from "../../../hooks/UseProjects";
import { useDispatch, useSelector } from "react-redux";
import Axios from "../../../redux/axiosInstance";
import {
  fetchAllProjects,
  selectLaunchedProjects,
  selectUnderDevelopmentProjects,
} from "../../../redux/projectSlice";
import { toast } from "react-toastify";
import { DEFAULT_SERVER_ERROR } from "../../../config/cms";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const Projects = () => {
  const [search, setSearch] = useState("");
  const { isLoading, data } = UseProjects();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const debounceSearchValue = useDebounce(search, 300);

  const udProjects = useSelector(selectUnderDevelopmentProjects);
  const launchedProject = useSelector(selectLaunchedProjects);

  const { pathname } = useLocation();
  const IS_RELEASED = pathname == "/projects/released";


  const filterData = useMemo(() => {
    let lowercaseSearch = debounceSearchValue?.toLowerCase();
    let data = IS_RELEASED ? launchedProject : udProjects;
    let filterdata = data?.filter((each) =>
      each?.title?.toLowerCase()?.includes(lowercaseSearch || "")
    );
    return filterdata;
  }, [debounceSearchValue, data, IS_RELEASED]);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const deleteProjectHandler = (item) => {
    setLoading(true);
    console.log(item, "item");
    Axios.delete(`/projects/${item.id}`)
      .then((res) => {
        setLoading(false);
        if (res?.data?.success) {
          dispatch(fetchAllProjects());
          toast.success(
            res?.data?.message || "Project Deleted Successfully..."
          );
        } else {
          toast.error(res?.data?.message || DEFAULT_SERVER_ERROR);
        }
      })
      .catch((e) => {
        setLoading(false);
        toast.error(e?.response?.data?.message || DEFAULT_SERVER_ERROR);
      });
  };

  return (
    <>
      {(isLoading || loading) && <Loader className="page_loader" />}
      <Card
        className={styles.card}
        title="Projects"
        classTitle={cn("title-purple", styles.title)}
        classCardHead={styles.head}
        head={
          <>
            <Form
              className={styles.form}
              value={search}
              setValue={setSearch}
              onSubmit={() => handleSubmit()}
              placeholder="Search project"
              type="text"
              name="search"
              icon="search"
            />
            <div className={styles.control}>
              <button
                className={cn("button-stroke button-small", styles.button)}
              >
                Deleted
              </button>
              <button
                className={cn("button-stroke button-small", styles.button)}
              >
                Set status
              </button>
              <div className={styles.counter}>3 selected</div>
            </div>
          </>
        }
      >
        <div className={styles.products}>
          <div className={styles.wrapper}>
            <Table
              deleteProjectHandler={deleteProjectHandler}
              items={filterData}
            />
          </div>
        </div>
      </Card>
    </>
  );
};

export default Projects;
