export const columns = [
    {
        header : "Month",
        accessor : ""
    },
    {
        header : "Launched",
        accessor : ""
    },
    {
        header : "Progress",
        accessor : ""
    },
    {
        header : "Engaged",
        accessor : ""
    },
    {
        header : "Registered",
        accessor : ""
    },
    {
        header : "Deposit",
        accessor : ""
    },
    {
        header : "Total Expenses",
        accessor : ""
    },
]