import React, { useEffect } from "react";
import cn from "classnames";
import styles from "./Home.module.sass";
import TooltipGlodal from "../../components/TooltipGlodal";
import Overview from "./Overview";
import PopularProducts from "../../components/PopularProducts";
import Comments from "./Comments";
import RefundRequests from "../../components/RefundRequests";
import ProTips from "./ProTips";
import MoreCustomers from "./MoreCustomers";
import RegistrationViews from "./ProductViews";
import { useDispatch, useSelector } from "react-redux";
import {
  dashboardLoaderSelector,
  dashboardSelector,
  fetchAllDashbaordInfo,
  fetchDashboardRegistrations
} from "../../redux/dashboardSlice";
import TrafficChannel from "./TrafficChannel";
import Loader from "../../components/Loader";

const Home = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAllDashbaordInfo());
    dispatch(fetchDashboardRegistrations());
  }, []);
  const isLoading = useSelector(dashboardLoaderSelector);

  return (
    <>
      <div className={styles.row}>
        {isLoading && <Loader />}
        <div style={{ flex: 1 }} className={styles.col}>
          <Overview className={styles.card} />
          <RegistrationViews className={styles.card} />
          {/* <ProTips className={styles.card} /> */}
          <TrafficChannel className={styles.card} />
          <MoreCustomers />
        </div>
        <div className={styles.col}>
          <PopularProducts className={styles.card} views="10" />
        </div>
      </div>
      <TooltipGlodal />
    </>
  );
};

export default Home;
