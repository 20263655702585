import React, { useMemo } from "react";
import styles from "./Table.module.sass";

const Row = ({ data, ga_submissions, afflaite_submissions }) => {
  const affliateSubmission = useMemo(() => {
    let month = data?.report_from;
    return afflaite_submissions?.find((each) => each.report_month == month);
  }, []);
  const gaSubmission = useMemo(() => {
    let month = data?.report_from;
    return ga_submissions?.find((each) => each.month == month);
  }, []);
  console.log(gaSubmission, "gaSubmission");
  console.log(affliateSubmission, "affliatesubmission");
  return (
    <div className={styles.row}>
      <div className={styles.col}>
        <div className={styles.label}>Month</div>
        <div className={`data `}>{data?.report_from}</div>
      </div>
      <div className={styles.col}>
        <div className={styles.label}>Launched</div>
        <div className={`data `}>{data?.launched_projects_count || 0}</div>
      </div>
      <div className={styles.col}>
        <div className={styles.label}>Progress</div>
        <div className={`data `}>{data?.progress_projects_count || 0}</div>
      </div>
      <div className={styles.col}>
        <div className={styles.label}>Engaged</div>
        <div className={`data `}>{gaSubmission?.users || "NA"}</div>
      </div>
      <div className={styles.col}>
        <div className={styles.label}>Registered</div>
        <div className={`data `}>
          {affliateSubmission?.registrations || "NA"}
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.label}>Deposit</div>
        <div className={`data `}>{affliateSubmission?.deposits || "NA"}</div>
      </div>
      <div className={styles.col}>
        <div className={styles.label}>Total Expenses</div>
        <div className={`data `}>{data?.total_amount}</div>
      </div>
    </div>
  );
};

export default Row;
