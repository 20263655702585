import React, { useEffect, useState } from "react";
import Modal from "../../../../components/Modal";
import "./CreatingHostingServer.scss";
import TextInput from "../../../../components/TextInput";
import CustomDropdown from "../../../../components/Dropdown/CustomDropdown";
import { websiteAllowedOptions } from "./data";
import SectionHeading from "../../../../components/Card/SectionHeading";
import TextArea from "../../../../components/TextInput/TextArea";
import UseApiCalling from "../../../../hooks/UseApiCalling";
import Loader from "../../../../components/Loader";
import { useDispatch } from "react-redux";
import { fetchAllExpences } from "../../../../redux/expensesSlice";
import { purchaseTypeOptions } from "../../../ThemePage/Table/CreateForm/data";
import { EXPENSE_API_TYPE } from "..";

const CreateForm = ({ show, setShow, updateData, setUpdateData }) => {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const { isLoading, callApi } = UseApiCalling();

  const closer = () => {
    setShow(false);
  };

  const dispatch = useDispatch();
  const cb = (response, isSuccess) => {
    if (isSuccess) {
      setShow(false);
      dispatch(fetchAllExpences(EXPENSE_API_TYPE));
      setValues({});
    }
  };

  const formSubmitHandler = (e) => {
    e.preventDefault();
    let payload = { ...values };
    delete payload.how_many_websites_allowed;
    delete payload.price_type;
    payload.how_many_websites_allowed =
      values?.how_many_websites_allowed?.value;
    payload.price_type = values?.price_type?.value;
    payload.type = EXPENSE_API_TYPE;
    if (updateData) {
      callApi(`/expenses/${updateData.id}`, payload, "put", cb);
    } else {
      callApi("/expenses", payload, "post", cb);
    }
  };

  useEffect(() => {
    if (updateData) {
      let update = { ...updateData };
      update.how_many_websites_allowed = websiteAllowedOptions?.find(
        (each) => each.value == updateData.how_many_websites_allowed
      );
      setValues(update);
    }
    return () => {
      setUpdateData(null);
    };
  }, [updateData, setUpdateData]);

  return (
    <Modal outerClassName="modal_full" visible={show} onClose={closer}>
      {isLoading && <Loader className="page_loader" />}
      <SectionHeading title="Add Plugin" classTitle="title-blue" />
      <form onSubmit={formSubmitHandler}>
        <div className="form_grid_2_cols">
          <TextInput
            values={values}
            setValues={setValues}
            label="Platform Name"
            name="company"
            type="text"
            required
            errors={errors}
            setErrors={setErrors}
          />
          <TextInput
            values={values}
            setValues={setValues}
            label="Platform Website"
            name="platform_website"
            type="url"
            required
            errors={errors}
            setErrors={setErrors}
          />
          <TextInput
            values={values}
            setValues={setValues}
            label="Plugin Name"
            name="title"
            type="text"
            required
            errors={errors}
            setErrors={setErrors}
          />
          <CustomDropdown
            name="price_type"
            options={purchaseTypeOptions}
            label="Price Type"
            values={values}
            setValues={setValues}
            isClearable
            errors={errors}
            setErrors={setErrors}
          />

          <TextInput
            values={values}
            setValues={setValues}
            label="Purchase Date"
            name="purchase_date"
            type="date"
            required
            errors={errors}
            setErrors={setErrors}
          />

          <TextInput
            values={values}
            setValues={setValues}
            label="Months"
            name="months"
            type="number"
            placeholder="1-60"
            required
            errors={errors}
            setErrors={setErrors}
          />
          <TextInput
            values={values}
            setValues={setValues}
            label="Support Period"
            name="support_period"
            placeholder="1-60"
            type="number"
            required
            errors={errors}
            setErrors={setErrors}
          />
          <TextInput
            values={values}
            setValues={setValues}
            label="Amount"
            name="amount"
            type="number"
            required
            errors={errors}
            setErrors={setErrors}
          />
          <TextInput
            values={values}
            setValues={setValues}
            label="Renewal Amount"
            name="renewal_amount"
            type="number"
            required
            errors={errors}
            setErrors={setErrors}
          />

          <CustomDropdown
            name="how_many_websites_allowed"
            options={websiteAllowedOptions}
            label="How many websites Allowed"
            values={values}
            setValues={setValues}
            isClearable
            errors={errors}
            setErrors={setErrors}
          />
        </div>

        <div className="mt-30">
          <TextArea
            rows="5"
            label="Description"
            tooltip="Description"
            name={"description"}
            values={values}
            setValues={setValues}
            errors={errors}
            setErrors={setErrors}
          />
        </div>

        <button type="submit" className={"button mt-30"}>
          {updateData ? "Update Plugin" : "Add Plugin"}
        </button>
      </form>
    </Modal>
  );
};

export default CreateForm;
