import React from "react";
import cn from "classnames";
import styles from "./Panel.module.sass";
import Icon from "../../../components/Icon";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { useSelector } from "react-redux";
import { projectSelector } from "../../../redux/projectSlice";
import { dateFormatter } from "../../../utils";

const Panel = ({
  publishNowClickHandler,
}) => {
  const { id } = useParams()
  const {detail} = useSelector(projectSelector);
    console.log(detail,'some detail')

  return (
    <div className={cn("panel", styles.panel)}>
    { detail?.updated_at && <div className={styles.info}>
        <Icon name="check-all" size="24" />
        Last saved <span>{dateFormatter(detail?.updated_at)}</span>
      </div>}
      <div className={styles.btns}>
        <button
          onClick={publishNowClickHandler}
          className={cn("button", styles.button)}
        >
          {
            !id ? "Create Project" : "Update Project"
          }
        </button>

      </div>
    </div>
  );
};

export default Panel;
