import React, { useEffect } from "react";
import ExpenseCard from "./ExpenseCard";

const ExpenseSection = ({ projectInfo, showDetail }) => {
  return (
    <div className={`expenses_cont ${!showDetail && " hidden_card"}`}>
      <h3 className="section_heading">Expenses List</h3>
      <div className="expenses_grid">
        {projectInfo?.expenses?.map((expense, i) => (
          <ExpenseCard
            showDetail={showDetail}
            project_id={projectInfo?.id}
            expense={expense}
            i={i}
          />
        ))}
      </div>
    </div>
  );
};

export default ExpenseSection;
