import React, { useMemo } from "react";
import cn from "classnames";
import styles from "./Users.module.sass";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { staffOverviewSelector } from "../../../redux/staffSlice";
import CustomImage from "../../../components/CustomImage/CustomImage";
import Icon from "../../../components/Icon";
import { dashboardSelector } from "../../../redux/dashboardSlice";


const Users = ({ className }) => {
  const dashboardData = useSelector(dashboardSelector);

  const data = useMemo(() => {
    let format = {
      top3Projects: null,
      total_projects: 0,
      launched_projects: 0,
    };
    let projects = dashboardData?.projects;
    if (!projects || projects?.length < 4) {
      format.top3Projects = projects;
    } else {
      format.top3Projects = projects.slice(0, 3);
    }
    format.total_projects = projects?.length;
    format.launched_projects = projects?.filter(
      (each) => each.is_project_launched === "true"
    )?.length;
    return format
  }, [dashboardData]);

  return (
    <div className={cn(styles.users, className)}>
      <div className={styles.head}>
        <div className={styles.info}>
          <strong>{data?.total_projects}</strong> projects with <strong>{data?.launched_projects} </strong> Launched
        </div>
        {/* <Link
          className={cn("button-stroke", styles.button)}
          to="/message-center"
        >
          Send<span> message</span>
        </Link> */}
      </div>
      <div className={styles.list}>
        {data?.top3Projects?.map((x, index) => (
          <Link className={styles.item} key={index} to={x.url}>
            <div className={styles.avatar}>
              {!x.cover_image ? (
                <img src="/images/content/avatar.jpg" alt="Avatar" />
              ) : (
                <CustomImage src={x.cover_image} alt="Avatar" />
              )}
            </div>
            <div className={styles.title}>{x.title}</div>
          </Link>
        ))}
        <Link className={styles.all} to="/projects/released">
          <div className={styles.icon}>
            <Icon name="arrow-right" size="24" />
          </div>
          <div className={styles.text}>View all</div>
        </Link>
      </div>
    </div>
  );
};

export default Users;
