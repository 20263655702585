import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from 'react-redux';
import { store } from './redux/store';
import {  GoogleOAuthProvider } from '@react-oauth/google';


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <GoogleOAuthProvider clientId="351644263127-qgeqa25r8isnih817cp1tu3ii3mhpvn8.apps.googleusercontent.com">
        <App />
      </GoogleOAuthProvider>
    </Provider>

  </React.StrictMode>,
  document.getElementById("root")
);
