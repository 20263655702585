import React from "react";
import { Route } from "react-router-dom";
import Page from "./components/Page";
// import Home from "./screens/Home";
// import ProductsDashboard from "./screens/ProductsDashboard";
// import NewProduct from "./screens/NewProduct";
import Released from "./screens/Released";
import Comments from "./screens/Comments";
import Scheduled from "./screens/Scheduled";
import Customers from "./Pages/Customers";
import CustomerList from "./Pages/CustomerList";
import Promote from "./screens/Promote";
import Notification from "./screens/Notification";
import Settings from "./screens/Settings";
import UpgradeToPro from "./screens/UpgradeToPro";
import MessageCenter from "./screens/MessageCenter";
import ExploreCreators from "./screens/ExploreCreators";
import AffiliateCenter from "./screens/AffiliateCenter";
// import Earning from "./screens/Expenses";
import Refunds from "./screens/Refunds";
import Payouts from "./screens/Payouts";
import Statements from "./screens/Statements";
// import Shop from "./screens/Shop";
import PageList from "./screens/PageList";
import "./App.scss";
import Home from "./Pages/Home";
import TrafficReport from "./Pages/TrafficReport";
import ProfilePage from "./Pages/ProfilePage/ProfilePage";
import UserRefreshLogin from "./hooks/UserRefreshLogin";
import Expenses from "./Pages/Expenses";
import HostingServerPage from "./Pages/HostingServerPage";
import NewProject from "./Pages/NewProject/NewProject";
import ApiPage from "./Pages/ApiPage";
import ThemesPage from "./Pages/ThemePage";
import PluginPage from "./Pages/PluginPage";
import SoftwarePage from "./Pages/SoftwarePage";
import SignIn from "./Pages/SignIn";
import SignUpPage from "./Pages/SignUpPage/SignUpPage";
import ProjectLisingPage from "./Pages/ProjectListingPage/ProjectListingPage";
import NewStaff from "./Pages/NewStaff/NewStaff";
import ClaimStatus from "./Pages/ClaimStatus/ClaimStatus";
import CostGenerate from "./Pages/CostGenerate/CostGenerate";
import CostGenerateHistory from "./Pages/CostGenerateHistory/CostGenerateHistory";

const Routes = () => {
 UserRefreshLogin();
  return (
    <>
      <Route
        exact
        path="/"
        render={() => (
          <Page title="Dashboard">
            <Home />
          </Page>
        )}
      />
      <Route
        exact
        path="/projects/traffic-report"
        render={() => (
          <Page title="Traffic Report">
            <TrafficReport />
          </Page>
        )}
      />
      <Route
        exact
        path="/projects/add"
        render={() => (
          <Page title="New Project">
            <NewProject />
          </Page>
        )}
      />
      <Route
        exact
        path="/projects/update/:id"
        render={() => (
          <Page title="Update Project">
            <NewProject />
          </Page>
        )}
      />
      <Route
        exact
        path="/projects/under-development"
        render={() => (
          <Page title="Under Development">
            <ProjectLisingPage />
          </Page>
        )}
      />
      <Route
        exact
        path="/projects/released"
        render={() => (
          <Page title="Released">
            <ProjectLisingPage />
          </Page>
        )}
      />
      <Route
        exact
        path="/products/comments"
        render={() => (
          <Page title="Comments">
            <Comments />
          </Page>
        )}
      />
      <Route
        exact
        path="/products/scheduled"
        render={() => (
          <Page title="Scheduled">
            <Scheduled />
          </Page>
        )}
      />
      <Route
        exact
        path="/customers/overview"
        render={() => (
          <Page title="Customers">
            <Customers />
          </Page>
        )}
      />
      <Route
        exact
        path="/customers/customer-list"
        render={() => (
          <Page title="Staff list">
            <CustomerList />
          </Page>
        )}
      />
      <Route
        exact
        path="/customers/claim-status"
        render={() => (
          <Page title="Claim Status">
            <ClaimStatus />
          </Page>
        )}
      />
      <Route
        exact
        path="/customers/add"
        render={() => (
          <Page title="Add New Staff">
            <NewStaff />
          </Page>
        )}
      />
      <Route
        exact
        path="/profile"
        render={() => (
          <Page wide>
            <ProfilePage />
          </Page>
        )}
      />
      <Route
        exact
        path="/expenses/overview"
        render={() => (
          <Page title="All Expenses">
            <Expenses />
          </Page>
        )}
      />
      <Route
        exact
        path="/income/refunds"
        render={() => (
          <Page title="Refunds">
            <Refunds />
          </Page>
        )}
      />
      <Route
        exact
        path="/expenses/hosting-server"
        render={() => (
          <Page title="Hosting Server">
            <HostingServerPage />
          </Page>
        )}
      />
      <Route
        exact
        path="/expenses/api"
        render={() => (
          <Page title="Api Providers">
            <ApiPage />
          </Page>
        )}
      />
      <Route
        exact
        path="/expenses/plugin"
        render={() => (
          <Page title="Plugins">
            <PluginPage />
          </Page>
        )}
      />
      <Route
        exact
        path="/expenses/software"
        render={() => (
          <Page title="Softwares">
            <SoftwarePage />
          </Page>
        )}
      />
      <Route
        exact
        path="/expenses/theme"
        render={() => (
          <Page title="Themes">
            <ThemesPage />
          </Page>
        )}
      />
      <Route
        exact
        path="/income/statements"
        render={() => (
          <Page title="Statements">
            <Statements />
          </Page>
        )}
      />
      <Route
        exact
        path="/promote"
        render={() => (
          <Page title="Promote">
            <Promote />
          </Page>
        )}
      />
      <Route
        exact
        path="/notification"
        render={() => (
          <Page title="Notification">
            <Notification />
          </Page>
        )}
      />
      <Route
        exact
        path="/settings"
        render={() => (
          <Page title="Settings">
            <Settings />
          </Page>
        )}
      />
      <Route
        exact
        path="/upgrade-to-pro"
        render={() => (
          <Page title="Upgrade to Pro">
            <UpgradeToPro />
          </Page>
        )}
      />
      <Route
        exact
        path="/message-center"
        render={() => (
          <Page title="Message center">
            <MessageCenter />
          </Page>
        )}
      />
      <Route
        exact
        path="/explore-creators"
        render={() => (
          <Page title="Explore creators">
            <ExploreCreators />
          </Page>
        )}
      />
      <Route
        exact
        path="/affiliate-center"
        render={() => (
          <Page title="Affiliate center">
            <AffiliateCenter />
          </Page>
        )}
      />
      <Route
        exact
        path="/report/cost-generate"
        render={() => (
          <Page title="Project Cost Generate">
            <CostGenerate />
          </Page>
        )}
      />
      <Route
        exact
        path="/report/report-history"
        render={() => (
          <Page title="Report History">
            <CostGenerateHistory />
          </Page>
        )}
      />
      <Route exact path="/sign-up" render={() => <SignUpPage />} />

      <Route exact path="/sign-in" render={() => <SignIn />} />
      <Route exact path="/pagelist" component={PageList} />
    </>
  );
};

export default Routes;
