import React from "react";
import cn from "classnames";
import styles from "./Item.module.sass";
import Icon from "../../../Icon";
import CustomImage from "../../../CustomImage/CustomImage";
import { DEFAULT_PROJECT_IMAGE } from "../../../Table/Row";

const Item = ({ className, item, onClick }) => {
  return (
    <div className={cn(styles.item, className)}>
      <div className={styles.link} onClick={onClick}>
        <div className={styles.preview}>

          {
            item?.cover_image ? <CustomImage
              src={item.cover_image}
              alt="Project"
            /> :
              <img src={DEFAULT_PROJECT_IMAGE} />
          }
        </div>
        <div className={styles.details}>
          <div className={styles.title}>{item.title}</div>
          <div className={styles.content}>{item.status || "No Status"}</div>
        </div>
      </div>
      <div className={styles.arrow}>
        <Icon name="arrow-next" size="24" />
      </div>
    </div>
  );
};

export default Item;
