import { BrowserRouter as Router, Switch } from "react-router-dom";
import "./styles/app.sass";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { fetchAllProjects } from "./redux/projectSlice";
import { fetchAllStaff } from "./redux/staffSlice";
import Routes from "./Routes";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAllProjects())
    dispatch(fetchAllStaff())
  }, [])

  return (
    < >
      <ToastContainer />
      <Router>
        <Switch>
          <Routes />
        </Switch>
      </Router>
    </>
  );
}

export default App;
