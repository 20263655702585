export const generalReportColumns = [
  {
    tooltip: "",
    label: "Period",
  },
  {
    tooltip: "",
    label: "Projects",
  },
  {
    tooltip: "",
    label: "Reg.",
  },
  {
    tooltip: "",
    label: "Session",
  },
  {
    tooltip: "",
    label: "Staff",
  },
  {
    tooltip: "Session Per Registration",
    label: "SPR",
  },
  {
    tooltip: "Cost Per Engaged Session",
    label: "CPS",
  },
  {
    tooltip: "Cost Per Registration",
    label: "CPR",
  },
];

export const reportData = [
  {
    title: "1 Oct - 15 Oct",
    project: {
      counter: "Player Vip",
      color: "#B5E4CA",
    },
    registrations: {
      counter: "24k",
      color: "#CABDFF",
      value: 37.8,
    },
    session: {
      counter: 48,
      color: "#B1E5FC",
      value: -37.8,
    },
    staff: {
      counter: 16,
      color: "#CABDFF",
      value: -56,
    },
    spr: {
      counter: 16,
      color: "#B5E4CA",
      value: -56,
    },
    cps: {
      counter: 16,
      color: "#FFD88D",
      value: -56,
    },
    cpr: {
      counter: 16,
      color: "#FFD88D",
      value: -56,
    },
  },
  {
    title: "1 Oct - 15 Oct",
    project: {
      counter: "Bj baji Landing",
      color: "#B5E4CA",
    },
    registrations: {
      counter: "24k",
      color: "#CABDFF",
      value: 37.8,
    },
    session: {
      counter: 48,
      color: "#B1E5FC",
      value: -37.8,
    },
    staff: {
      counter: 16,
      color: "#CABDFF",
      value: -56,
    },
    spr: {
      counter: 16,
      color: "#B5E4CA",
      value: -56,
    },
    cps: {
      counter: 16,
      color: "#FFD88D",
      value: -56,
    },
    cpr: {
      counter: 16,
      color: "#FFD88D",
      value: -56,
    },
  },
];

// input affliates and ga_submissions to a function
const affliates = [
  {
    name: "affliate 1",
    report_month: "2024-01-01",
  },
  {
    name: "affliate 2",
    report_month: "2024-02-01",
  },
];

const ga_submissions = [
  {
    name: "ga submission 1 ",
    report_month: "2024-01-01",
  },
];

// output be group of ga_submissions and affliates based on report month

const result = [
  {
    report_month: "2024-01-01",
    affliate: {
      name: "affliate 1",
      report_month: "2024-01-01",
    },
    ga_submission: {
      name: "ga submission 1 ",
      report_month: "2024-01-01",
    },
  },
  {
    report_month: "2024-01-01",
    affliate: {
      name: "affliate 2",
      report_month: "2024-02-01",
    },
    ga_submission: null,
  },
];
