import React, { useEffect, useState } from "react";
import styles from "./NewProduct.module.sass";
import TooltipGlodal from "../../components/TooltipGlodal";
import NameAndDescription from "./NameAndDescription";
import ImagesAndCTA from "./ImagesAndCTA";
import DomainAndServer from "./DomainAndServer/index";
import ThemeAndPlugin from "./ThemeAndPlugin";
import TeamsSection from "./TeamsSection";
import Panel from "./Panel";
import { stateToHTML } from "draft-js-export-html";
import "./NewProject.scss";
import Axios from "../../redux/axiosInstance";
import Loader from "../../components/Loader";
import { toast } from "react-toastify";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllStaff } from "../../redux/staffSlice";
import { formDataToObject, validateForm } from "../../utils";
import {
  countriesOptions,
  projectCategoryOptions,
  requiredProjectFields,
} from "./data";
import {
  clearDetailProjectData,
  fetchProject,
  projectSelector,
} from "../../redux/projectSlice";
import { ContentState, EditorState, convertFromHTML } from "draft-js";
import { ROLE_TYPES } from "./TeamsSection/data";
import { fetchAllTypesExpenses } from "../../redux/expensesSlice";

const NewProject = () => {
  const [formValues, setFormValues] = useState({});
  const [teams, setTeams] = useState({});
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const history = useHistory();
  const { id } = useParams();
  const projectStore = useSelector(projectSelector);
  const { isLoading, detail } = projectStore;

  const FormSubmitHandler = () => {
    // const { isError, errors } = validateForm(formValues, requiredProjectFields);
    // if (isError) {
    //   toast.error("Some of Required Field not filled , pls fill ...");
    //   setErrors(errors);
    //   return;
    // }
    // console.log(isError, errors);
    const contentState = formValues?.description?.getCurrentContent();
    let contentHtml;
    if (contentState) contentHtml = stateToHTML(contentState);

    let keyfeatures = `${formValues?.key_feature_1 || ""},${
      formValues?.key_feature_2 || ""
    },${formValues?.key_feature_3 || ""},${formValues?.key_feature_4 || ""}`;
    // let countries = formValues?.countries?.map((each) => each.value);
    // countries = countries?.join(",");
    let countries = formValues?.countries?.map((each) => each.value);
    let formdata = new FormData();

    formdata.append("title", formValues?.title || "");
    formdata.append("key_features", keyfeatures || "");
    formdata.append("code", formValues?.code || "");
    formdata.append("category", formValues?.category?.value || "");
    formdata.append("description", contentHtml || "");
    formdata.append("countries", countries || "");
    formdata.append("is_domain_bought", !!formValues?.is_domain_bought);
    formdata.append("is_project_launched", !!formValues?.is_project_launched);
    formdata.append(
      "first_year_domain_amount",
      formValues?.first_year_domain_amount || ""
    );
    formdata.append(
      "recurring_domain_amount",
      formValues?.recurring_domain_amount || ""
    );
    formdata.append("domain_name", formValues?.domain_name || "");

    if (formValues?.cover_image)
      formdata.append("cover_image", formValues?.cover_image);

    if (formValues?.logo) formdata.append("logo", formValues?.logo);

    let payloadTeams = [];
    Object.keys(teams)?.forEach((role_id) => {
      let data = teams[role_id]?.map((each) => ({
        role_id,
        user_id: each?.value,
      }));
      payloadTeams = [...payloadTeams, ...data];
    });

    formdata.append("teams", JSON.stringify(payloadTeams));

    let expenses = [];
    let hosting_server = formValues?.hosting_server?.value;
    let theme_name = formValues?.theme_name?.value;
    let api_provider = formValues?.api_provider?.value;
    let paid_pluins = formValues?.paid_plugin;
    if (hosting_server) expenses.push(hosting_server);
    if (theme_name) expenses.push(theme_name);
    if (paid_pluins?.length) expenses = [...expenses, ...paid_pluins];
    if (api_provider) expenses.push(api_provider);
    if (expenses?.length) {
      formdata.append("expenses", JSON.stringify(expenses));
    }

    if (id) formdata.append("_method", "put");

    if (id && typeof formValues.cover_image == "string") {
      formdata.delete("cover_image");
    }
    if (id && typeof formValues.logo == "string") {
      formdata.delete("logo");
    }

    let payload = formdata;

    formDataToObject(payload);
    //  console.log(expenses,'some expenses')
    //  return;

    console.log(payload, "payload");
    setLoading(true);

    (!id
      ? Axios.post("projects", payload)
      : Axios.post(`projects/${id}`, payload, {
          headers: { "Content-Type": "multipart/form-data" },
        })
    )
      .then((res) => {
        setLoading(false);
        let response = res?.data;
        toast.success(response?.message || "Project Created Successfully...");
        history.push(`/projects/under-development`);
      })
      .catch((e) => {
        setLoading(false);
        const response = e?.response?.data;
        toast.error(response?.message);
        if (Object?.keys(response?.errors)?.length) {
          setErrors(response?.errors);
        }
        console.log(e?.response?.data);
      });
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAllStaff());
    if (id && !detail) {
      dispatch(fetchProject(id));
    }
    return () => {
      setFormValues({});
      dispatch(clearDetailProjectData());
    };
  }, [id]);

  const optionsGenerate = (data) => {
    let options = data?.map((each) => ({
      label: each?.user?.name,
      value: each?.user?.id,
    }));
    return options;
  };
  const optionsGeneratebyKeys = (data, label_key, value_key = "id") => {
    if (Array.isArray(data)) {
      let options = data?.map((each) => ({
        label: each?.[label_key],
        value: each?.[value_key],
      }));
      return options;
    } else return { label: data?.[label_key], value: data?.[value_key] };
  };

  useEffect(() => {
    if (detail) {
      let update = {};
      update.title = detail.title || "";
      update.code = detail.code || "";
      const contentBlocks = convertFromHTML(detail.description);
      const contentState = ContentState.createFromBlockArray(contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      update.description = editorState;
      update.category = projectCategoryOptions?.find(
        (each) => each.value === detail.category
      );

      let keyFeatures = (detail.key_features || "")?.split(",");
      keyFeatures?.forEach((feature, i) => {
        update[`key_feature_${i + 1}`] = feature;
      });

      let countries_ids = (detail.countries || "")?.split(",");
      let countryAudience = countriesOptions.filter((country) =>
        countries_ids?.includes(country?.value)
      );
      update.countries = countryAudience;
      update.cover_image = detail.cover_image;
      update.logo = detail.logo;

      update.domain_name = detail.domain_name;
      update.first_year_domain_amount = detail.first_year_domain_amount;
      update.recurring_domain_amount = detail.recurring_domain_amount;

      update.is_domain_bought = detail.is_domain_bought;
      update.is_project_launched = detail.is_project_launched == "true";

      /// EXPENSES SETUP
      const hosting_server_expenses = detail.expenses.find(
        (each) => each.type == "server"
      );
      const theme_expenese = detail.expenses.find(
        (each) => each.type == "theme"
      );
      const api_expenses = detail.expenses.filter((each) => each.type == "api");

      update.theme_name = optionsGeneratebyKeys(theme_expenese, "title");
      update.hosting_server = optionsGeneratebyKeys(
        hosting_server_expenses,
        "company"
      );
      update.api_provider = optionsGeneratebyKeys(api_expenses, "company");
      update.paid_plugin = detail.expenses
        .filter((each) => each.type == "plugin")
        ?.map((each) => each.id);

      const teamsUpdate = {};
      Object.keys(ROLE_TYPES)?.map((each) => {
        let key = ROLE_TYPES[each];
        teamsUpdate[key] = optionsGenerate(
          detail.teams.filter((each) => each?.role_id === key)
        );
      });
      // teamsUpdate[ROLE_TYPES.productManager] = optionsGenerate(
      //   detail.teams.filter(
      //     (each) => each?.role_id === ROLE_TYPES.productManager
      //   )
      // );
      // teamsUpdate[ROLE_TYPES.frontend] = optionsGenerate(
      //   detail.teams.filter((each) => each?.role_id === ROLE_TYPES.frontend)
      // );
      // teamsUpdate[ROLE_TYPES.backend] = optionsGenerate(
      //   detail.teams.filter((each) => each?.role_id === ROLE_TYPES.backend)
      // );
      // teamsUpdate[ROLE_TYPES.qc] = optionsGenerate(
      //   detail.teams.filter((each) => each?.role_id === ROLE_TYPES.qc)
      // );
      setTeams(teamsUpdate);

      setFormValues(update);
    }
  }, [detail]);

  useEffect(() => {
    dispatch(fetchAllTypesExpenses());
  }, [dispatch]);

  console.log(formValues, "some form values");

  return (
    <>
      {(loading || isLoading) && <Loader className="page_loader" />}
      <div className={styles.row}>
        <div className={styles.col}>
          <NameAndDescription
            values={formValues}
            setValues={setFormValues}
            className={styles.card}
            errors={errors}
            setErrors={setErrors}
          />
          <ImagesAndCTA
            values={formValues}
            setValues={setFormValues}
            className={styles.card}
            errors={errors}
            setErrors={setErrors}
          />
          <DomainAndServer
            values={formValues}
            setValues={setFormValues}
            className={styles.card}
            errors={errors}
            setErrors={setErrors}
          />
          <ThemeAndPlugin
            values={formValues}
            setValues={setFormValues}
            className={styles.card}
            errors={errors}
            setErrors={setErrors}
          />
          <TeamsSection
            values={teams}
            setValues={setTeams}
            className={styles.card}
            errors={errors}
            setErrors={setErrors}
          />
        </div>
      </div>
      <Panel publishNowClickHandler={FormSubmitHandler} />
      <TooltipGlodal />
    </>
  );
};

export default NewProject;
