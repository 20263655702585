export const expensesOverviewData = [
  {
    type: "office",
    title: "Office Labour Cost",
    icon: "activity",
    color: "#B5E4CA",
    tooltip: "Small description Earning",
  },
  {
    type: "remote",
    title: "Remote Labour Cost",
    icon: "pie-chart",
    color: "#CABDFF",
    tooltip: "Small description Balance",
  },
  {
    type: "utility",
    title: "Utilities Cost",
    icon: "shopping-bag",
    color: "#B1E5FC",
    tooltip: "Small description Total",
  },
];
