export const websiteAllowedOptions =[
    {
        label : 'Unlimited',
        value : 0,
    },
    {
        label : '1 - 100',
        value : 100,
    }
]
export const purchaseTypeOptions =[
    {
        label : 'Subscribe',
        value : "subscribe",
    },
    {
        label : 'One Time',
        value : "one_time",
    }
]