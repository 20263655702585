import React, { useEffect, useState, useMemo } from "react";
import CustomModal from "../../../MyComponents/CustomModal/CustomModal";
import ProjectCost from "../../CostGenerate/ProjectCost";
import UseApiCalling from "../../../hooks/UseApiCalling";
import Loader from "../../../components/Loader";
import Pill from "../../../MyComponents/Pill/Pill";
import { getEndMonth, getMonthFromDate } from "../../../utils";
import { ROLE_TYPES } from "../../NewProject/TeamsSection/data";
import { exportToExcel } from 'react-json-to-excel';


const DetailReport = ({ closer, data }) => {
  const { isLoading, callApi } = UseApiCalling();
  const [detail, setDetail] = useState(null);

  const cb = (res, isSuccess) => {
    if (isSuccess) {
      setDetail(res?.data?.report_summary);
    }
  };
  const detailParse = useMemo(() => {
    if (!detail) return null;
    return JSON.parse(detail);
  }, [detail]);

  useEffect(() => {
    callApi(`/project-cost-save/${data.id}`, null, "get", cb, false);
  }, []);

  const getStaffNameByRole = (team, role) => {
    let person = team.find((each) => each.role_id == role);
    return person?.user?.name || "";
  };
  const getExpenseNameByType = (expenses, type) => {
    let expense = expenses.find((each) => each.type == type);
    return expense?.title || expense?.company ||"";
  };
  const formatExcelData = (data) => {
    let format = {
      Project: data?.code,
      "Product Manager": getStaffNameByRole(data.teams,ROLE_TYPES.productManager),
      "Product Manager ( vice ) ": "",
      UIUX: getStaffNameByRole(data.teams,ROLE_TYPES.uiUx),
      Frontend: getStaffNameByRole(data.teams,ROLE_TYPES.frontend),
      Backend: getStaffNameByRole(data.teams,ROLE_TYPES.backend),
      "Backend Backup": getStaffNameByRole(data.teams,ROLE_TYPES.backendBackup),
      "Wordpress Technical": getStaffNameByRole(data.teams,ROLE_TYPES.wordpressTechnical),
      "Graphic Designer": getStaffNameByRole(data.teams,ROLE_TYPES.graphicDesigner),
      "QC & Complain": getStaffNameByRole(data.teams,ROLE_TYPES.qc),
      "Offpage Research": getStaffNameByRole(data.teams,ROLE_TYPES.offpageResearch),
      "Competitor Research": getStaffNameByRole(data.teams,ROLE_TYPES.competitorResearch),
      "Inpage SEO": getStaffNameByRole(data.teams,ROLE_TYPES.inpageSeo),
      "Content 1 ( Lead )": getStaffNameByRole(data.teams,ROLE_TYPES.content1),
      "Content 2": getStaffNameByRole(data.teams,ROLE_TYPES.content2),
      "Content 3": getStaffNameByRole(data.teams,ROLE_TYPES.content3),
      "Fake Comment ( Web & Social )": getStaffNameByRole(data.teams,ROLE_TYPES.fakeComment),
      "Social Media": getStaffNameByRole(data.teams,ROLE_TYPES.socialMedia),
      Launched: data?.is_project_launched=="true"?"Yes":"No",
      Traffic: 0,
      "Registrations ": 0,
      "Hosting Server":getExpenseNameByType(data?.expenses,'server'),
      Api: getExpenseNameByType(data?.expenses,'api'),
      Theme: getExpenseNameByType(data?.expenses,'theme'),
      Plugin: getExpenseNameByType(data?.expenses,'plugin'),
      Software: getExpenseNameByType(data?.expenses,'software'),
      "Project Cost": getProjectCosts()?.find(each=>each.project_id==data?.id)?.project_cost,
    };
    return format;
  };

  const getProjectCosts = () =>{
    let projectCosts = [];
    const costs = document.querySelectorAll(`.project_cost`);
    for (let i = 0; i < costs.length; i++) {
      let project_cost = +costs[i]?.innerHTML;
      let project_id = costs[i]?.getAttribute("project_id");
      projectCosts.push({
        project_cost,
        project_id,
      });
    }
    return projectCosts;
  }
  const downloadHandler = () => {

    let formattedData = detailParse?.map((each) => formatExcelData(each));
    exportToExcel(formattedData, `Report ${getMonthFromDate(data.report_from)} - ${data.total_amount}`)
    console.log(formattedData,'some formatted data');
  };

  console.log(data, "some data");
  console.log(detailParse, "some detail");
  return (
    <CustomModal visible onClose={closer}>
      <div className="flex justify-between">
        <div className="flex align-center gap-20 mb-20">
          <Pill isGreenDark>
            <h3>{getMonthFromDate(data?.report_from)}</h3>
          </Pill>
          <Pill isGreenDark>
            <h3>${data?.total_amount}</h3>
          </Pill>
        </div>
        <button onClick={downloadHandler} className="button mb-20">
          Download Excel Report
        </button>
      </div>

      {isLoading && <Loader />}
      {detailParse?.map((each) => (
        <ProjectCost project={each} />
      ))}
    </CustomModal>
  );
};

export default DetailReport;
