import React from 'react'
import Table from './Table'

const CostGenerateHistory = () => {

  return (
    <div>
        <Table />
    </div>
  )
}

export default CostGenerateHistory