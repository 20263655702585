import React, { useState } from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import Checkbox from "../../../../../components/Checkbox/index.js";
import Balance from "../../../../../components/Balance/index.js";
import Control from "../../Control/index.js";
import CustomImage from "../../../../../components/CustomImage/CustomImage.jsx";
import { DEFAULT_PROJECT_IMAGE } from "../../../../../components/Table/Row/index.js";
import { useHistory } from "react-router-dom/cjs/react-router-dom.js";
import { useDispatch, useSelector } from "react-redux";
import { setDetailProject } from "../../../../../redux/projectSlice.js";
import ModalProject from "../../../../../MyComponents/ModalProject/index.js";
import { selectIsAdmin } from "../../../../../redux/userSlice.js";
import { divide } from "../../../../../utils.js";

const Row = ({ item, value, onChange, up, deleteProjectHandler }) => {
  const [visibleActions, setVisibleActions] = useState(false);
  const [visibleModalProduct, setVisibleModalProduct] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  const actionClickHandler = (type) => {
    setVisibleActions(false);
    if (type == "edit") {
      dispatch(setDetailProject(item));
      history.push(`/projects/update/${item.id}`);
    } else if (
      type == "delete" &&
      window.confirm(`Are you sure you want to delete`)
    ) {
      deleteProjectHandler(item);
    }
  };
  const isAdmin = useSelector(selectIsAdmin);

  return (
    <>
      <div className={styles.row} onMouseLeave={() => setVisibleActions(false)}>
        {/* <div className={styles.col}>
          <Checkbox
            className={styles.checkbox}
            value={value}
            onChange={onChange}
          />
        </div> */}
        <div className={styles.col}>
          <div
            className={styles.item}
            onClick={() => setVisibleModalProduct(true)}
          >
            <div className={styles.preview}>
              {item?.cover_image || item?.logo ? (
                <CustomImage
                  src={item?.logo || item.cover_image}
                  alt="Project"
                />
              ) : (
                <img src={DEFAULT_PROJECT_IMAGE} />
              )}
            </div>
            <div className={styles.details}>
              <div className={styles.product}>{item.title}</div>
              <div className={styles.wrap}>
                <div className={styles.price}>${item.price || 0}</div>
                <div className={styles.category}>{item.domain_name}</div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.label}>Status</div>
          {!item.is_hold ? (
            <div className={cn("status-green", styles.status)}>Active</div>
          ) : (
            <div className={cn("status-red", styles.status)}>Deactive</div>
          )}
          {isAdmin && (
            <Control
              project={item}
              className={styles.control}
              visibleActions={visibleActions}
              setVisibleActions={setVisibleActions}
              up={up}
              actionClickHandler={actionClickHandler}
            />
          )}
        </div>
        <div className={styles.col}>
          <div className={styles.label}>Monthly Cost</div>$
          {(
            item?.project_costs_sum_project_cost /
            (item?.project_costs?.length || 1)
          )?.toFixed(2)}
        </div>
        <div className={styles.col}>
          <div className={styles.label}>Reg.</div>
          <div className={styles.sales}>
            <div className={styles.number}>
              {item?.affliates_sum_registrations || 0}
            </div>
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.label}>Total Cost</div>
          <div className={styles.box}>
            <div className={styles.number}>
              ${item?.project_costs_sum_project_cost || 0}
            </div>
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.label}>CPR</div>
          <div className={styles.box}>
            <div className={styles.number}>
              {divide(
                item?.project_costs_sum_project_cost,
                item?.affliates_sum_registrations,
                true
              )}
            </div>
          </div>
        </div>
      </div>
      {visibleModalProduct && (
        <ModalProject
          data={item}
          visible={visibleModalProduct}
          onClose={() => setVisibleModalProduct(false)}
        />
      )}
    </>
  );
};

export default Row;
