import React from "react";
import Icon from "../../components/Icon";
import classNames from "classnames";
import styles from "./UploadFileButton.module.sass";

const UploadFileButton = ({ values, setValues, ...props }) => {
  const fileChangeHandler = (e) => {
    let file = e.target?.files?.[0];
    let name = props.name;
    setValues({ ...values, [name]: file });
  };
  return (
    <div className={styles.file}>
      <input {...props} onChange={fileChangeHandler} type="file" />
      <button className={classNames("button", styles.button)} type="button">
        <Icon name="add" size="24" />
        <span>Upload new picture</span>
      </button>
    </div>
  );
};

export default UploadFileButton;
