import { useDispatch, useSelector } from "react-redux";
import { fetchAllProjects } from "../redux/projectSlice";
import { useEffect } from "react";

const UseProjects = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAllProjects());
  }, []);
  const { isLoading, data } = useSelector((store) => store.projects);
  return { data, isLoading };
};

export default UseProjects;
